import {Routes} from '@angular/router';
import {FolderListComponent} from './folder-list/folder-list.component';
import {CreateFolderComponent} from './create-form/create-folder.component';
import {FolderWorkflowComponent} from './folder-workflow/folder-workflow.component';
import {Context} from '../../models/enums/context.enum';
import {CreateFolderRepairerComponent} from './create-form/create-folder-repairer/create-folder-repairer.component';
import {AppAuthGuard} from '../../shared/utils/app.authguard';

export const FolderRoutes: Routes = [
    {
        path: 'folder',
        data: {
            context: [Context.REPARATION, Context.SAV, Context.PRODUCT_RECALL]
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                pathMatch: 'full',
                component: FolderListComponent,
                canActivate: [AppAuthGuard]
            },
            {
                path: 'create',
                pathMatch: 'full',
                component: CreateFolderComponent,
                canActivate: [AppAuthGuard],
                data: {
                    data: {
                        scope: 'create.folder'
                    }
                }
            },
            {
                path: 'repairer_create',
                pathMatch: 'full',
                component: CreateFolderRepairerComponent,
                canActivate: [AppAuthGuard]
            },
            {
                path: ':folderId',
                pathMatch: 'full',
                component: FolderWorkflowComponent,
                canActivate: [AppAuthGuard]
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'create',
            },
            {
                path: 'create',
                component: CreateFolderComponent,
                canActivate: [AppAuthGuard]
            },
            {
                path: '**',
                redirectTo: '',
            }
        ]
    }
];

import {Injectable} from '@angular/core';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {PushNotificationPayload} from '../folder-workflow/push-notification-payload';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import {FolderSubjectService} from '../folder-subject.service';
import {FolderService} from '../../../shared/services/folder.service';
import {Folder} from '../../../models/folder.model';

export const TOPIC = '/topic/bo-notifications/';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    stompClient: any;
    concernedEvents = ['PAYMENT_REQUEST_REFERENCE_ADDED', 'SPARE_PARTS_ORDER_REFERENCE_ADDED', 'FOLDER_ATTACHMENT_ADDED'];

    constructor(private snackBar: SnackBarService, private translateService: TranslateService,
                private folderSubjectService: FolderSubjectService, private folderService: FolderService) {
    }

    initWebsocket(claimId: string): void {
        const ws = new SockJS(environment.pushNotificationWebSocketEndpoint);
        this.stompClient = Stomp.over(ws);
        this.stompClient.connect({}, () => {
            this.stompClient.subscribe(TOPIC + claimId, (pushNotificationEvent) => {
                const pushNotificationPayload = JSON.parse(pushNotificationEvent.body) as PushNotificationPayload;
                const events = pushNotificationPayload?.data?.events || [];
                events.forEach(event => {
                    const shouldProcessEvent = this.concernedEvents.includes(event.eventName) || event.eventType === 'WORKFLOW_STATUS';
                    if (!shouldProcessEvent) {
                        return;
                    }
                    this.folderService.getFolder(claimId).subscribe(claim => {
                        this.publishClaim(claim);
                        if (this.concernedEvents.includes(event.eventName)) {
                            this.displaySnackBar(event.eventName);
                        }
                        if (event.eventType === 'WORKFLOW_STATUS') {
                            this.folderSubjectService.refreshWorkflowStatusLoaded(claim);
                        }
                    });
                });
            });
        });
    }

    private publishClaim(claim: Folder) {
        this.folderSubjectService.folderLoaded(claim);
    }

    private displaySnackBar(eventName: string) {
        const message = this.translateService.instant(`FOLDER.EVENT.ENUM_LABELS.${eventName}`);
        this.snackBar.openAtEnd('Info', message);
    }

    disconnect(claimId: string): void {
        if (!!this.stompClient && this.stompClient.connected) {
            this.stompClient.disconnect(() => {
                console.log(`Websocket disconnected for claim ${claimId}.`);
            });
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../models/folder.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FolderExternalReferencesDialogComponent} from '../folder-external-references-dialog/folder-external-references-dialog.component';
import {FolderCommentsModalComponent} from '../folder-detail-tabs-card/folder-comments-modal/folder-comments-modal.component';
import {FolderSiteModalComponent} from '../folder-detail-tabs-card/folder-site-modal/folder-site-modal.component';
import {SiteType} from '../../../models/enums/siteType.enum';
import {FileService} from '../../../shared/services/file.service';
import {FolderService} from '../../../shared/services/folder.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RepairCenterService} from '../../../shared/services/repairCenter.service';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {SendToExternalSystemModalComponent} from './send-to-external-system-modal/send-to-external-system-modal.component';
import {FolderSubjectService} from '../folder-subject.service';
import {Observable} from 'rxjs';
import {CommentType} from '../../../models/enums/CommentType.enum';
import {Comment} from '../../../models/comment.model';
import {ConfirmDialogWithCommentComponent} from '../../../../@fuse/components/confirm-dialog-with-comment/confirm-dialog-with-comment.component';
import {finalize, take} from 'rxjs/operators';
import {TagEnums} from '../../../models/enums/tag.enums';
import {PushNotificationFolderModalComponent} from './push-notification-folder-modal/push-notification-folder-modal.component';
import {PushNotificationService} from '../../../shared/services/pushNotification.service';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {PushNotification, PushNotificationUpdateRequest} from '../../../models/pushNotification/pushNotification.model';
import {EditManagementSiteModalComponent} from '../../../shared/generic/edit-managment-site-modal/edit-management-site-modal.component';
import {AddExternalReferencesDialogComponent} from '../add-external-references-dialog/add-external-references-dialog.component';
import {ChangeCurrentStatusDialogComponent} from '../change-current-status-dialog/change-current-status-dialog.component';
import {SuspendingFolderReasonDialogComponent} from '../suspending-folder-reason-dialog/suspending-folder-reason-dialog.component';
import {SendingCommunicationDialogComponent} from '../sending-communication-dialog/sending-communication-dialog.component';
import {SendingProtocolNotificationComponent} from '../sending-protocol-notification/sending-protocol-notification.component';
import {FolderProductLocationComponent} from '../folder-detail-tabs-card/folder-product-location/folder-product-location.component';
import {ProductLocationTypes} from '../../../models/product.model';
import {CloseModal} from '../../../shared/modal/CloseModal';
import {Context} from '../../../models/enums/context.enum';
import {AlertFolderModalComponent} from './alert-folder-modal/alert-folder-modal.component';
import {Site} from '../../../models/site.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {hasScope, isAdminPlatana} from '../../../store/user/user.selectors';
import {currentLanguage} from '../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../unsubscriber';
import {SelectWorkOrderDialogComponent} from './select-work-order-dialog/select-work-order-dialog.component';
import {AnonymizeDialogComponent} from '../anonymize-dialog/anonymize-dialog.component';
import {ProductUtils} from '../../../shared/utils/product-utils';
import {Scopes} from '../../../models/scopes/scopes.model';
import {BackOfficeService} from '../../../shared/services/back-office.service';
import {ReportRuleGenerated, ReportTemplate} from '../../../models/report.template';
import {NotificationCommandAudit} from '../../../models/notification.command.audit';
import {SnackBarService} from '../../../shared/services/snack-bar.service';
import * as moment from 'moment/moment';
import {StatusEnum} from '../../../models/enums/StatusEnum';
import {OrderSubjectService} from '../../../shared/services/order-subject.service';

@Component({
    selector: 'app-folder-snapshot-card',
    templateUrl: './folder-snapshot-card.component.html',
    styleUrls: ['./folder-snapshot-card.component.scss']
})
export class FolderSnapshotCardComponent extends Unsubscriber implements OnInit {

    constructor(private folderService: FolderService,
                public dialog: MatDialog,
                private alertService: PushNotificationService,
                private fileService: FileService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private repairCenterService: RepairCenterService,
                private translateService: TranslateService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private pushNotificationSubjectService: PushNotificationSubjectService,
                private pushNotificationService: PushNotificationService,
                private backOfficeService: BackOfficeService,
                private orderSubjectService: OrderSubjectService,
                private snackBar: SnackBarService) {
        super();
    }

    @Input() variablesTask: any;

    private closeModal = new CloseModal();
    folder: Folder;
    isRollBackAllowed = true;
    isOrderNotConfirmed = true;
    isAdminPlatana$: Observable<boolean>;
    isAdmin$: Observable<boolean>;
    currentLanguage$: Observable<string>;
    managementSite: string;
    productLocation = ProductLocationTypes.CLIENT;
    statusTime: number;
    customStatusLabel: string;

    totalElementNotification;
    totalAlerts = 0;
    page = 0;
    availableReportList: ReportTemplate[] = [];
    availableNotificationList: NotificationCommandAudit[] = [];
    productPosition: string;
    protected readonly Scopes = Scopes;

    ngOnInit(): void {
        this.isAdminPlatana$ = this.store$.pipe(select(isAdminPlatana));
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
                this.folder = folder;
                this.checkOrderStatus();
                this.statusTime = !!this.folder.currentWorkflowStatus ? moment(moment()).diff(this.folder.currentWorkflowStatus.workflowStatusDate) : null;
                this.managementSite = this.getNameOfManagementSite();
                this.productLocation = ProductUtils.latestLocation(this.folder.product);
                this.productPosition = ProductUtils.latestPosition(this.folder.product);
                this.initAlerts();
                this.findUserTask();
            }
        );
        this.anotherSubscription = this.pushNotificationSubjectService
            .pushNotification$.subscribe(() => {
                this.initPushNotifications();
            });

        this.anotherSubscription = this.pushNotificationSubjectService.alert$
            .subscribe(() => {
                this.initAlerts();
            });

        this.activatedRoute.queryParams
            .pipe(take(1))
            .subscribe(params => {
                if (params?.showComments === 'true') {
                    this.showAllComments();
                }
            });
    }

    findUserTask(): void {
        this.anotherSubscription = this.backOfficeService.getInstructionUserTaskByCode(this.getUserTaskCode()).subscribe(
            userTask => {
                this.customStatusLabel = userTask?.label;
            }
        );
    }

    getSizeComments(): number {
        return Number(this.commentsSize(this.folder.customerComments)) + Number(this.commentsSize(this.folder.agentComments))
            + Number(this.commentsSize(this.folder.repairerComments)) + Number(this.commentsSize(this.folder.supplierComments));
    }

    commentsSize(obj): number {
        let count = 0;
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                count += obj[key].length;
            }
        }
        return count;
    }

    getReferencesSize(object: any): number {
        return Object.keys(object)
            .filter(key => !key.startsWith('LINKED_CLAIM_TECHNICAL_ID'))
            .filter(key => !key.startsWith('RESERVATION_LEGACY_380'))
            .length;
    }

    openExternalReferencesViewDialog(): void {
        const externalReferences = new Map(Object.entries(this.folder.externalReferences));
        const keysToRemove = Object.keys(this.folder.externalReferences)
            .filter(key => key.startsWith('LINKED_CLAIM_TECHNICAL_ID') || key.startsWith('RESERVATION_LEGACY_380'));

        keysToRemove.forEach(hiddenkey => {
            if (externalReferences.has(hiddenkey)) {
                externalReferences.delete(hiddenkey);
            }
        });
        this.dialog.open(FolderExternalReferencesDialogComponent, {
            data: externalReferences
        });

    }

    openAddExternalReferencesDialog(): void {
        const dialog = this.dialog.open(AddExternalReferencesDialogComponent, {
            minWidth: '30%',
            width: '900px',
            disableClose: true,
            data: this.folder
        });
        this.closeModal.closeWithEscape(dialog);
    }

    showAllComments(): void {
        const dialogRef = this.dialog.open(FolderCommentsModalComponent, {
            width: '1100px',
            minWidth: '30%',
            disableClose: true,
            data: {folder: this.folder}
        });
        dialogRef.afterClosed().subscribe();
        this.closeModal.closeWithEscape(dialogRef);
    }

    showAllSites(): void {
        const dialogRef = this.dialog.open(FolderSiteModalComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {sites: this.folder.sites}
        });
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe();
    }

    showAllAlerts(): void {
        this.alertService.getAlertByBusinessId(this.folder.id).subscribe(value => {
            if (!!value) {
                const dialogRef = this.dialog.open(AlertFolderModalComponent, {
                    width: '900px',
                    minWidth: '30%',
                    disableClose: true,
                    data: {
                        alerts: value,
                        showButton: false,
                    },
                });
                this.closeModal.closeWithEscape(dialogRef);
                dialogRef.afterClosed().subscribe();
            }
        }, err => {
            console.log('ERROR get alert details' + err);
        });
    }

    showProductLocation(): void {
        const dialogRef = this.dialog.open(FolderProductLocationComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {folder: this.folder, currentLocation: this.productLocation}
        });
        this.closeModal.closeWithEscape(dialogRef);
        this.anotherSubscription = dialogRef.afterClosed().subscribe((folder) =>
            this.folderSubjectService.folderLoaded(folder));
    }

    getNameOfManagementSite(): string {
        const site = this.folder.sites.find(e => e.type === SiteType.MANAGEMENT_SITE);
        if (!!site) {
            if (site.name) {
                return site.code + '-' + site.name;
            } else {
                return site.code;
            }
        }
        return '';
    }

    removeFolder(): void {
        this.isAdminPlatana$.pipe(take(1))
            .subscribe(isAdminPlatana => {
                if (isAdminPlatana) {
                    this.showConfirmationModal();
                }
            });
    }

    showConfirmationModal(): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialogRef.componentInstance.title = 'CONFIRMATION.MODAL.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'CONFIRMATION.MODAL.REMOVE.FOLDER';
        dialogRef.componentInstance.confirmButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.CONFIRM_BUTTON';
        dialogRef.componentInstance.cancelButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.CANCEL_BUTTON';
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(validate => {
            if (validate) {
                this.store$.dispatch(new StartLoading());
                this.folderService.removeFolder(this.folder.id).then(value => {
                    const _this = this;
                    setTimeout(() => {
                        _this.store$.dispatch(new StopLoading());
                        this.pushNotificationSubjectService.refreshPushNotification(new PushNotification());
                        _this.router.navigate(['folder', 'list']);
                    }, 500);

                }).catch(reason => {
                    this.store$.dispatch(new StopLoading());
                    console.log('ERROR delete Folder details' + reason);
                });
            }
        });
    }


    sendAsychroneToRepairCenter(): void {
        this.repairCenterService.sendAsychroneToRepairCenter(this.folder.id).subscribe(() => {
        });
    }

    sendAsynchroneToExternalSystem(): void {
        const dialogRef: MatDialogRef<SendToExternalSystemModalComponent> = this.dialog.open(SendToExternalSystemModalComponent,
            {
                width: '900px',
                minWidth: '30%',
                disableClose: true,
            });
        dialogRef.componentInstance.systemName = this.folder.organization.code === 'BL' ? 'Phebus' : '';
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.store$.dispatch(new StartLoading());
                this.folderService.resendToExternalSystem(this.folder.id, this.folder.organization.code, data).subscribe(() => {
                    this.store$.dispatch(new StopLoading());
                }, () => this.store$.dispatch(new StopLoading()));
            }
        });
    }

    changeManagementSite(): void {
        const dialogRef = this.dialog.open(EditManagementSiteModalComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {
                site: this.getManagementSite(),
                folder: this.folder
            }
        });
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(siteConfig => {
                if (!!siteConfig) {
                    const site = {
                        code: siteConfig.code,
                        name: siteConfig.name,
                        address: siteConfig.address,
                        type: SiteType.MANAGEMENT_SITE,
                        contact: siteConfig.contact
                    };
                    const folderUpdateRequest = {
                        sites: [site]
                    };
                    this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then((folder) => {
                        this.folder = folder;
                        this.folderSubjectService.folderLoaded(folder);
                    });
                }
            }
        );
    }

    showAllPushNotificationFolder(): void {
        const dialogRef = this.dialog.open(PushNotificationFolderModalComponent, {
            width: '1000px',
            minWidth: '30%',
            data: {folderId: this.folder.id},
            disableClose: true,
        });

        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(data => {
            const notifications = data.filter(element => element.status === 'UNREAD');
            const notificationIds = notifications.map(notif => notif.id);

            if (notificationIds.length > 0) {
                this.pushNotificationService.updateNotificationsStatus(notificationIds, 'READ').subscribe(updatedNotifications => {
                    updatedNotifications.forEach(updatedNotif => {
                        const index = data.findIndex(notif => notif.id === updatedNotif.id);
                        if (index !== -1) {
                            data[index] = updatedNotif;
                        } else {
                            data.push(updatedNotif);
                        }
                        this.pushNotificationSubjectService.refreshPushNotification(updatedNotif);
                    });
                });
            }
        });
    }

    initPushNotifications(): any {
        const pushNotificationFilterRequest: any = {
            businessId: this.folder.id,
            status: ['READ', 'UNREAD']

        };
        this.pushNotificationService.countPushNotifications(pushNotificationFilterRequest).subscribe(number => {
            this.totalElementNotification = number;
        }, err => {
            console.log('ERROR get Push notification details' + err);
        });
    }

    initAlerts(): any {
        const filters = new Map;
        filters.set('businessId', this.folder.id);
        this.pushNotificationService.countAlerts(filters).subscribe(number => {
            this.totalAlerts = number;
        }, err => {
            console.log('ERROR when get alert number' + err);
        });
    }


    getManagementSite(): Site {
        const sites = this.folder.sites.filter(site => site.type === SiteType.MANAGEMENT_SITE);
        if (sites.length > 0) {
            return sites[0];
        }
        return null;
    }

    checkIfFolderClosed(): boolean {
        return this.folder.currentStatus.status.toString().startsWith('FOLDER_CLOSED');
    }

    // notif du protocol spécifique pour LM campagne Store banne
    checkIfLMAndStoreBanne(): boolean {
        return (!!this.folder.organization && this.folder.organization.code === 'LM' && !!this.folder.businessLink && this.folder.businessLink === 'STORE_BANNE');
    }

    closeFolder(): void {
        const dialogRef = this.dialog.open(ConfirmDialogWithCommentComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: this.folder
        });
        dialogRef.componentInstance.title = this.translateService.instant('FOLDER.CLOSE_FORM.TITLE');
        dialogRef.componentInstance.message = this.translateService.instant('FOLDER.CLOSE_FORM.MESSAGE');
        dialogRef.componentInstance.commentMessage = this.translateService.instant('FOLDER.COMMENT_FORM.FOLDER_CLOSED.LABEL');
        dialogRef.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.VALIDATE');
        dialogRef.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        this.anotherSubscription = dialogRef.afterClosed().subscribe(closeFolderComment => {
                if (!!closeFolderComment) {
                    this.store$.dispatch(new StartLoading());
                    const folderUpdateRequest = {
                        agentComment: {
                            content: closeFolderComment.comment,
                            type: CommentType.COMMENT_GENERAL_AGENT
                        } as Comment
                    } as IFolderUpdateRequest;

                    if (!!closeFolderComment.currentStatus) {
                        folderUpdateRequest['currentStatus'] = closeFolderComment.currentStatus;
                        folderUpdateRequest['currentWorkflowStatus'] = closeFolderComment.currentStatus;
                    }

                    this.folderService.closeFolder(this.folder.id, folderUpdateRequest).subscribe((folder) => {
                            this.folder = folder;
                            this.folderSubjectService.folderLoaded(folder);
                            this.store$.dispatch(new StopLoading());
                        },
                        () => {
                            this.store$.dispatch(new StopLoading());
                        });
                }
            }
        );
    }

    updateFavorites(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest = {
            tag: TagEnums.FAVORITES
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then((folder) => {
            this.folder = folder;
            this.store$.dispatch(new StopLoading());
            this.folderSubjectService.folderLoaded(folder);
        }).catch(reason => {
            this.store$.dispatch(new StopLoading());
        });
    }

    isFolderFavorite(): boolean {
        return this.folder.tags.includes(TagEnums.FAVORITES);
    }


    changeCurrentWorkflowStatusDialog(): void {
        const dialogRef = this.dialog.open(ChangeCurrentStatusDialogComponent, {
            minWidth: '30%',
            width: '900px',
            disableClose: true, data: {
                folder: this.folder, currentStatus: this.folder.currentWorkflowStatus.status
            }
        });
        this.closeModal.closeWithEscape(dialogRef);
    }


    /**
     * Following a request from the business team, this button must be temporarily hidden
     */
    suspendFolder(): void {
        // Todo ask what if we want to cancel waiting
        const dialogRef = this.dialog.open(SuspendingFolderReasonDialogComponent, {
            width: '900px', minWidth: '30%', disableClose: true
        });
        this.closeModal.closeWithEscape(dialogRef);
        dialogRef.afterClosed().subscribe(suspendingFolderReason => {
            if (suspendingFolderReason) {
                this.store$.dispatch(new StartLoading());

                const folderUpdateRequest: IFolderUpdateRequest = {
                    pendingStatus: {
                        reason: suspendingFolderReason.reason, comment: suspendingFolderReason.comment
                    }
                };
                this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then((folder) => {
                    this.folder = folder;
                    this.folderSubjectService.folderLoaded(folder);
                    this.store$.dispatch(new StopLoading());
                }).catch(reason => {
                    this.store$.dispatch(new StopLoading());
                });

            }
        });
    }

    sendCommunication(): void {
        const dialogRef = this.dialog.open(SendingCommunicationDialogComponent, {
            width: '900px', minWidth: '30%', maxHeight: '700px', data: this.folder,
            disableClose: true
        });
        this.closeModal.closeWithEscape(dialogRef);
    }

    sendProtocolNotification(): void {
        this.dialog.open(SendingProtocolNotificationComponent, {
            width: '700px', minWidth: '700px', maxHeight: '500px', data: this.folder, disableClose: false
        });
    }

    reOpenFolder(): void {
        this.store$.dispatch(new StartLoading());
        this.folderService.startWorkflow(this.folder.id)
            .pipe(
                finalize(() => this.store$.dispatch(new StopLoading()))
            )
            .subscribe(restartedFolder => {
                    this.folderSubjectService.folderLoaded(restartedFolder);
                    this.folderSubjectService.refreshWorkflowStatusLoaded(restartedFolder);
                },
                () =>
                    console.error('an error occurred while starting the workflow process for the folder ' + this.folder.id)
            );
    }

    isProductRecallContext(): boolean {
        return this.folder.context === Context.PRODUCT_RECALL;
    }

    anonymizeCustomerDetails(): void {
        const dialogRef = this.dialog.open(AnonymizeDialogComponent, {
            data: {
                customer: {
                    id: this.folder.customer.reference,
                    firstName: this.folder.customer.firstName,
                    lastName: this.folder.customer.lastName,
                }
            }
        });

        dialogRef.afterClosed().subscribe(agreed => {
            if (agreed) {
                this.store$.dispatch(new StartLoading());
                this.anotherSubscription = this.folderService.anonymize(this.folder.id)
                    .subscribe(folder => {
                            this.folderSubjectService.folderLoaded(folder);
                            this.store$.dispatch(new StopLoading());
                        },
                        () => this.store$.dispatch(new StopLoading())
                    );
            }
        });
    }

    hasWorkOrderWithoutNumber(): boolean {
        return this.folder.workOrders && this.folder.workOrders.some(workOrder => !workOrder.number);
    }

    displayFetchWorkOrderNumbersDialog(): void {
        const dialogRef = this.dialog.open(SelectWorkOrderDialogComponent, {
            minWidth: '30%',
            width: '600px',
            disableClose: true,
            data: {
                workOrders: this.folder.workOrders.filter(workOrder => !workOrder.number)
            }
        });

        dialogRef.afterClosed().subscribe(workOrderId => {
            if (workOrderId) {
                this.store$.dispatch(new StartLoading());
                this.folderService.fetchWorkOrderNumber(this.folder.id, workOrderId).subscribe(
                    next => {
                        this.store$.dispatch(new StopLoading());
                        this.snackBar.openWithIcon('WORK_ORDER_NUMBER_FETCHED.SUCCESS', 'Success');
                        this.anotherSubscription = this.folderService.getFolder(this.folder.id).subscribe(folder => {
                            this.folderSubjectService.folderLoaded(folder);
                        });
                    },
                    error => {
                        this.store$.dispatch(new StopLoading());
                        this.snackBar.openWithIcon('WORK_ORDER_NUMBER_FETCHED.ERROR', 'Error');
                    });
            }
        });
    }

    folderCanBeRollback(): boolean {
        return !this.checkIfFolderClosed() && !!this.folder.currentWorkflowStatus;
    }

    fetchAvailableReport() {
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.fetchAvailableReport(this.folder.id).subscribe(reports => {
            this.store$.dispatch(new StopLoading());
            this.availableReportList = [...new Map(reports.map(item => [item.reportCode, item])).values()];
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    regenerateReport(report: ReportTemplate) {
        this.store$.dispatch(new StartLoading());
        const reportRuleGenerated: ReportRuleGenerated = {
            code: report.reportCode,
            claimId: this.folder.id,
            warrantyCode: this.folder.newWarranty.warranty,
            productLocation: this.productLocation
        };
        this.backOfficeService.regenerateReport(reportRuleGenerated).subscribe(() => {
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    fetchAvailableCommunication() {
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.fetchAvailableCommunication(this.folder.id).subscribe(communication => {
            this.store$.dispatch(new StopLoading());
            this.availableNotificationList = communication;
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    regenerateNotification(notificationCommandAudit: NotificationCommandAudit) {
        this.store$.dispatch(new StartLoading());
        this.backOfficeService.regenerateNotification(notificationCommandAudit).subscribe(() => {
            this.store$.dispatch(new StopLoading());
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    getTimeDays(): number {
        return Math.trunc(moment.duration(this.statusTime).asDays());
    }

    getTimeHours(): number {
        return Math.trunc(moment.duration(this.statusTime).asHours());
    }

    getTimeMinutes(): number {
        return Math.trunc(moment.duration(this.statusTime).asMinutes());
    }

    private getUserTaskCode() {
        if (this.isCustomerDecisionTask()) {
            return 'CUSTOMER_DECISION';
        } else if (this.isCustomerDidNotRespondTask()) {
            return 'CUSTOMER_DID_NOT_RESPOND';
        } else {
            return this.folder.currentWorkflowStatus.status;
        }
    }

    isCustomerDecisionTask(): boolean {
        return this.folder.currentStatus.status === StatusEnum.FOLDER_CREATED && this.folder.context === 'SAV';
    }

    isCustomerDidNotRespondTask(): boolean {
        return this.folder.currentStatus.status === StatusEnum.FOLDER_CREATED && this.folder.context === 'PRODUCT_RECALL';
    }

    private checkIsRollBackAllowed() {
        this.isRollBackAllowed = this.folder.orderIds.length > 0 ? this.isOrderNotConfirmed : true;
    }

    private checkOrderStatus() {
        this.orderSubjectService.isNotConfirmed$.subscribe(isNotConfirmed => {
            this.isOrderNotConfirmed = isNotConfirmed;
            this.checkIsRollBackAllowed();
        });
    }

    public hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));
}

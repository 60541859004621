<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="row" class="w-100-p mb-4" fxLayoutAlign="space-between center">
        <div>
            <h1 class="m-0">{{'NAV.NOTIFICATIONS.LIST' | translate}}</h1>
        </div>
        <button mat-stroked-button (click)="updateStatusOfNotificationSelected()" type="submit"
                class="mt-4 aster-modal-btn_cancel"
                [disabled]="notificationsSelected.length === 0"
                [ngClass]="notificationsSelected.length === 0 ? 'h-60 mt-4  aster_btn_disabled' : 'aster-modal-btn_cancel' ">
            {{'BUTTON.IGNORED.NOTIFICATIONS' | translate}}
        </button>
    </div>
    <div fxLayout="column" class="w-100-p overflow">
        <ng-container *ngIf="!!filterForm">
            <form [formGroup]="filterForm">
                <table class="w-100-p" mat-table multiTemplateDataRows [dataSource]="dataSourceNotification.dataSource"
                       *ngIf="!!dataSourceNotification.dataSource">
                    <ng-container matColumnDef="NOTIFICATION_TYPE">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{'NOTIFICATION.TYPE' | translate}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <mat-label>{{'NOTIFICATION.TYPE' | translate}}</mat-label>
                                <mat-select
                                    formControlName="type"
                                    (ngModelChange)="onSelectionChange($event)">
                                    <mat-option *ngFor="let type of notificationTypes"
                                                [value]="type">
                                        {{'NOTIFICATION.' + type | translate}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="filterForm.value.type.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="filterForm.value.type.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('type')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{'NOTIFICATION.' + element.type | translate}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="NOTIFICATION_MESSAGE">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{'NOTIFICATION.MESSAGE' | translate}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <mat-label>{{'NOTIFICATION.MESSAGE' | translate}}</mat-label>
                                <mat-select
                                    formControlName="message"
                                    (ngModelChange)="onSelectionChange($event)">
                                    <mat-option *ngFor="let message of messages"
                                                [value]="message">
                                        {{'PUSH.NOTIFICATION.' + message | translate}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="filterForm.value.message.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="filterForm.value.message.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('message')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{getNotificationKey(element.extraInfo[element.type], element.type) | translate}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="NOTIFICATION_FOLDER_ID">
                        <th class="header pt-16 px-16 max-w-150" mat-header-cell *matHeaderCellDef>
                            <div>
                                {{'NOTIFICATION.FOLDER_ID' | translate}}
                            </div>
                            <mat-form-field class="filter w-100-p" floatLabel="never">
                                <input matInput
                                       (keyup.backspace)="resetSearch(filterForm.value.businessId)"
                                       (keyup.enter)="searchNotifications()"
                                       formControlName="businessId">
                                <mat-icon matSuffix class="disabled-text"
                                          *ngIf="filterForm.value.businessId.length === 0">
                                    search
                                </mat-icon>
                                <button type="button" mat-icon-button matSuffix class="disabled-text"
                                        *ngIf="filterForm.value.businessId.length > 0"
                                        color="primary" (click)="onClearSearchFormFiled('businessId')">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="cursor-pointer" (click)=goToFolder(element)> {{element.businessId}}</a>

                        </td>
                    </ng-container>
                    <!-- Actions Column -->
                    <ng-container matColumnDef="ACTION">
                        <th mat-header-cell *matHeaderCellDef> {{'FOLDER.PUSH.NOTIFICATION.ACTION' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container
                                *ngIf="element.status==='RESOLVED' || element.status ==='IGNORED' ; else StatusPushNotification">

                                {{('FOLDER.PUSH.NOTIFICATION.' + element.status) |translate}}
                            </ng-container>
                            <ng-template #StatusPushNotification>
                                <button mat-stroked-button (click)="updateStatus('RESOLVED', element)" type="submit"
                                        class="mr-12 mt-4 aster-modal-btn_validate">
                                    {{'BUTTON.RESOLVED' | translate}}
                                </button>
                                <button mat-stroked-button (click)="updateStatus('IGNORED',element)" type="submit"
                                        class="mt-4 aster-modal-btn_cancel">
                                    {{'BUTTON.IGNORED' | translate}}
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef=CHECKED>
                        <th mat-header-cell width="10%" *matHeaderCellDef></th>
                        <td class=" checkbox" mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="row.status !=='IGNORED' && row.status !=='RESOLVED'">
                                <mat-checkbox (change)="checkedRow($event, row)"></mat-checkbox>
                            </ng-container>
                        </td>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="dataSourceNotification.columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: dataSourceNotification.columnsToDisplay;"
                        id="rowTable"></tr>
                </table>
            </form>
        </ng-container>
    </div>
    <mat-paginator *ngIf="!!dataSourceNotification.page"
                   [length]="dataSourceNotification.page.totalElements"
                   [pageSizeOptions]="dataSourceNotification.pageSizeOptions"
                   (page)="paginate($event)"
                   [pageSize]="dataSourceNotification.page.size"
                   [pageIndex]="pageIndex"
                   class="w-100-p"></mat-paginator>
</div>
import {Component, OnInit} from '@angular/core';
import {DashboardV2Service} from '../../../../shared/services/dashboard-v2.service';
import {MatDialog} from '@angular/material/dialog';
import {tap} from 'rxjs/operators';
import {Dashboard} from '../../../../models/dashboard/dashboard.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {LoadDashboard, SaveDashboard} from '../../../../store/dashboard/dashboard.actions';
import {BoldBI} from '@boldbi/boldbi-embedded-sdk';
import {AddEditDashboardDialogComponent} from '../add-edit-dashboard-dialog/add-edit-dashboard-dialog.component';

@Component({
    selector: 'create-dashboard',
    template: '<div fxFlex id="create-dashboard"></div>'
})
export class CreateDashboardComponent implements OnInit {

    private readonly containerId = 'create-dashboard';

    dashboard: Dashboard;

    constructor(private dashboardService: DashboardV2Service,
                private dialog: MatDialog,
                private store$: Store<AppState>) {
    }

    ngOnInit() {
        this.store$.dispatch(new LoadDashboard({
            embedContainerId: this.containerId,
            mode: BoldBI.Mode.Design,
            onSave: this.saveDashboard
        }));
    }

    saveDashboard = dashboardData => {
        this.dashboard = dashboardData;
        this.dialog.open(AddEditDashboardDialogComponent, {
            data: {save: this.saveConfig},
            width: '30%',
            disableClose: true
        });
    }

    saveConfig = request => this.dashboardService.save({
        ...request,
        dashboard: this.dashboard
    }).pipe(tap(() => this.store$.dispatch(new SaveDashboard(this.dashboard.dashboardId))))

}




import {createSelector} from '@ngrx/store';
import {KeyCloackAttributes, UserState} from './user.state';
import {AppState} from '../app.state';
import {Context} from '../../models/enums/context.enum';
import {Roles} from '../../models/enums/roles.enum';
import {UserUtils} from './user.utils';
import {CodeLabel} from '../../models/element.model';

export const currentUser = (state: AppState) => state.user;

const selectAttributes = createSelector(
    currentUser,
    (user: UserState) => user.attributes
);

export const contextAttributes = createSelector(
    selectAttributes,
    (attributes: KeyCloackAttributes) => attributes.contexts
);

export const organizationAttributes = createSelector(
    selectAttributes,
    (attributes: KeyCloackAttributes) => attributes.organizations
);

export const fullName = createSelector(
    currentUser,
    (user: UserState) => (user.firstName ?? '') + ' ' + (user.lastName ?? '')
);

export const email = createSelector(
    currentUser,
    (user: UserState) => user.email
);

export const currentContext = createSelector(
    currentUser,
    (user: UserState) => user.context
);

export const isReparationContext = createSelector(
    currentContext,
    (context: string) => context === Context.REPARATION
);

export const isSAV = createSelector(
    currentContext,
    (context: string) => context === Context.SAV
);

export const isProductRecall = createSelector(
    currentContext,
    (context: string) => context?.startsWith(Context.PRODUCT_RECALL)
);

export const isNeitherProductRecallNorReparationContext = createSelector(
    isReparationContext,
    isProductRecall,
    (isReparationContext, isProductRecall) => !isReparationContext && !isProductRecall
);

export const isNeitherSAVNorProductRecall = createSelector(
    isSAV,
    isProductRecall,
    (isSAV, isProductRecall) => !isSAV && !isProductRecall
);

export const affectedSite = createSelector(
    currentUser,
    (user: UserState) => user.affectedSite
);

export const accessSites = createSelector(
    currentUser,
    (user: UserState) => user.accessSites
);

export const organizationCode = createSelector(
    currentUser,
    (state: UserState) => state.organizationCode
);

export const isOrganizationCodeEmpty = createSelector(
    organizationCode,
    (organizationCode: string) => organizationCode === '' || organizationCode === 'ALL'
);

export const isContextEmpty = createSelector(
    currentContext,
    (context: string) => context === ''
);

const scopes = createSelector(
    currentUser,
    (user: UserState) => {
        if (user.scopes .length === 0) {
            return [ 'noscopes'];
        }
        return user.scopes;
    }
);

const areScopesEmpty = createSelector(
    scopes,
    (scopes: string[]) => scopes?.length === 0
);

export const userContextNotReady = createSelector(
    isOrganizationCodeEmpty,
    isContextEmpty,
    areScopesEmpty,
    (isOrganizationCodeEmpty, isContextEmpty, areScopesEmpty) => isOrganizationCodeEmpty || isContextEmpty || areScopesEmpty
);

export const isLeroyMerlin = createSelector(
    organizationCode,
    (organization: string) => organization.startsWith('LM') || organization === 'BC_IT'
);

export const isElectroDepot = createSelector(
    organizationCode,
    (organization: string) => organization.startsWith('ED')
);

export const isLMPL = createSelector(
    organizationCode,
    (organization: string) => organization === 'LM_PL'
);

export const isLmRussia = createSelector(
    organizationCode,
    (organization: string) => organization === 'LM_RU'
);

export const isLMES = createSelector(
    organizationCode,
    (organization: string) => organization === 'LM_ES'
);

export const isLMPT = createSelector(
    organizationCode,
    (organization: string) => organization === 'LM_PT'
);

export const isLMIT = createSelector(
    organizationCode,
    (organization: string) => organization === 'LM_IT'
);
export const isNorauto = createSelector(
    organizationCode,
    (organization: string) => ['NR_FR', 'NR_ES', 'AU_BE'].includes(organization)
);
export const isBL = createSelector(
    organizationCode,
    (organization: string) => organization === 'BL'
);

export const hasInvoiceHint = createSelector(
    organizationCode,
    (organization: string) => ['LM_ES', 'LM_PL', 'LM_PT', 'LM_IT'].includes(organization)
);

export const isLmSpainOrPolandOrItaly = createSelector(
    organizationCode,
    (organization: string) => ['LM_ES', 'LM_PL', 'LM_IT'].includes(organization)
);

export const invoiceHint = createSelector(
    hasInvoiceHint,
    organizationCode,
    (hasInvoiceHint: boolean, organizationCode: string) => {
        return hasInvoiceHint ? ['assets/images/invoice/' + organizationCode + '_invoice-info.png'] : [];
    }
);

export const organizationLabel = createSelector(
    currentUser,
    (user: UserState) => user.organizationLabel
);

export const currentBusinessLink = createSelector(
    currentUser,
    (user: UserState) => user.businessLink
);

export const affectedBusinessLinks = createSelector(
    currentUser,
    (user: UserState) => user.affectedBusinessLinks
);

export const displayCampaignField = createSelector(
    affectedBusinessLinks,
    isProductRecall,
    (businessLinks: CodeLabel[], isProductRecall) => businessLinks.length > 0 && isProductRecall
);

const roles = createSelector(
    currentUser,
    (user: UserState) => user.roles
);

export const isAdminPlatana = createSelector(
    roles,
    (roles: string[]) => roles.includes(Roles.ROLE_ADMIN_PLATANA)
);

export const isAuthenticated = createSelector(
    currentUser,
    (user: UserState) => user.isAuthenticated
);

const hasRequiredRole = createSelector(
    roles,
    (roles: string[], props) => !props.requiredRole || roles.includes(props.requiredRole)
);

export const hasRequiredScope = createSelector(
    scopes,
    (scopes: string[], props) => !props.requiredScope || scopes.includes(props.requiredScope)
);

export const hasRequiredContext = createSelector(
    currentContext,
    (context: string, props) => !props.requiredContext || props.requiredContext.includes(context)
);

export const isUserAllowed = createSelector(
    hasRequiredRole,
    hasRequiredScope,
    hasRequiredContext,
    isAdminPlatana,
    (hasRequiredRoles, hasRequiredScope, hasRequiredContext, isAdminPlatana) =>
        hasRequiredContext && (isAdminPlatana || (hasRequiredRoles && hasRequiredScope))
);

export const hasScope = createSelector(
    scopes,
    (scopes, props) => scopes.includes(props.scope)
);

export const hasNoScope = createSelector(
    scopes,
    (scopes, props) => !scopes.includes(props.scope)
);

export const hasAccessToFolder = createSelector(
    accessSites,
    roles,
    (accessSites, roles: string[], props) =>
        UserUtils.userHasAccess(accessSites, props.folder.sites)
);

export const isRoleAuthorized = createSelector(
    isAdminPlatana,
    scopes,
    currentContext,
    (isAdminPlatana, scopes, userContext, props) =>
        (!props.item.context || props.item.context.includes(userContext)) &&
        (isAdminPlatana || UserUtils.isAllowedByScopes(scopes, props.item))
);


export const isFirstLoad = createSelector(
    currentUser,
    (user: UserState) => user.isFirstLoad
);

export const isUserAssignedToSite = createSelector(
    affectedSite,
    roles,
    (site: string, roles: string[]) => {
        if (roles.includes('ROLE_ADMIN')) {
            return true;
        } else if (roles.includes('ROLE_USER') && !site) {
            return false;
        }
        return true;
    }
);
export const isTechnicalAdmin = createSelector(
    roles,
    (roles: string[]) => roles.includes(Roles.ROLE_TECHNICAL_ADMIN)
);

/* tslint:disable:max-line-length */
import {Injectable} from '@angular/core';
import {GLOBAL} from '../../app-config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Symptom} from '../../models/symptom.model';
import {Page} from '../../models/page.model';
import {Observable} from 'rxjs';
import {Invoice, InvoiceItem, InvoiceProducts} from '../../models/invoice.model';
import {InterventionReservationRequest, InterventionSuggestionsResponse, InterventionSuggestRequest} from '../generic/calendar-rdv/appointment.models';
import {Folder, RefundRequest, RepairCenter, SerialNumberRule} from '../../models/folder.model';
import {Campaign} from '../../models/campaign.model';
import {Originator} from '../../models/originator.model';
import {IConfigRepairerDepartments} from '../../models/config-repairer-departments.model';
import {AppointmentProposal} from '../../models/appointment.model';
import {ModOpDetails} from '../../models/mod.op.list.details';
import {ReasonDetails} from '../../models/warranty.reason.list.details';
import {Carrier, ProductDeliveryRule} from 'app/models/product-delivery-rule';
import {ConfigExtraCodesEnum, ICodeLabelObjectWithExtra} from 'app/models/codeLabelObjectWithExtra.model';
import {HttpUtils} from './http-utils';
import {ProductWarrantyRequest, WarrantyRule} from '../../models/warrantyRule.model';
import {ProductAccessory} from '../../models/ProductAccessory';
import {WarrantyReasonDetails} from '../../models/warrantyReasonDetails';
import {AlertDto, AlertInfo} from '../../models/Alert.model';
import {SubElement} from '../../models/SubElement';
import {RuleEvaluationContext} from '../../models/rules/RuleEvaluationContext';
import {UserState} from '../../store/user/user.state';
import {ReportRuleGenerated, ReportTemplate} from '../../models/report.template';
import {NotificationCommandAudit} from '../../models/notification.command.audit';
import {PageSearchRequest} from '../../models/page-search-request.model';
import * as moment from 'moment/moment';
import {Instructions} from '../../models/instructions.model';
import {Address, Location} from '../../models/customer/customer.model';
import {Workforce} from '../../models/Workforce.model';
import {take} from 'rxjs/operators';
import {InstructionUserTask} from '../../models/instruction-user-task.model';
import {Role} from '../../models/permession.model';
import {Price} from '../../models/spare-parts/relatedSparePart.model';
import {RequestPaymentResponse} from '../../models/RequestPaymentResponse';

@Injectable({
    providedIn: 'root'
})
export class BackOfficeService {

    private path = `${GLOBAL.gatewayEndpoint}/back-office-service/api`;

    constructor(private httpClient: HttpClient) {
    }


    getSymptoms(ruleEvaluationContext: RuleEvaluationContext): Observable<Symptom[]> {
        return this.httpClient.post<Symptom[]>(`${this.path}/v4/symptoms/best-match`, ruleEvaluationContext);
    }

    getProductDeliveryRule(ruleEvaluationContext: RuleEvaluationContext): Observable<ProductDeliveryRule> {
        return this.httpClient.post<ProductDeliveryRule>(`${this.path}/rules/product-delivery-rule`, ruleEvaluationContext);
    }

    getCarriersList(carriers: Carrier[]): Observable<Carrier[]> {
        return this.httpClient.post<Carrier[]>(`${this.path}/carriers/list`, carriers);
    }
    getEligibilities(ruleEvaluationContext: RuleEvaluationContext): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.path}/v4/eligibilities/best-match`, ruleEvaluationContext);
    }

    getSerialNumberRule(ruleEvaluationContext: RuleEvaluationContext): Observable<SerialNumberRule> {
        return this.httpClient.post<SerialNumberRule>(`${this.path}/v4/serial-numbers/best-match`, ruleEvaluationContext);
    }
    getLocalRepairers(ruleEvaluationContext: RuleEvaluationContext): Observable<RepairCenter[]> {
        return this.httpClient.post<RepairCenter[]>(`${this.path}/v4/sites/local-repairers`, ruleEvaluationContext);
    }
    private buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

    getEligibleOperatingMode(ruleEvaluationRequest: RuleEvaluationContext): Observable<ModOpDetails[]> {
        return this.httpClient.post<ModOpDetails[]>(`${this.path}/rules/operating-modes`, ruleEvaluationRequest);
    }

    getEligibleWarrantyReasons(ruleEvaluationRequest: RuleEvaluationContext): Observable<ReasonDetails[]> {
        return this.httpClient.post<ReasonDetails[]>(`${this.path}/rules/warranty-reasons`, ruleEvaluationRequest);
    }

    searchInvoice(invoice: string): Observable<Invoice> {
        return this.httpClient.get<Invoice>(`${this.path}/invoice/${invoice}`);
    }

    searchInvoiceProducts(invoice: string, page: string, size: string): Observable<InvoiceProducts> {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        return this.httpClient.get<InvoiceProducts>(`${this.path}/invoice/${invoice}/products`, {params: params});
    }

    searchInvoiceByClientReference(clientReference: string, beginDate: string, endDate: string): Observable<InvoiceItem[]> {
        let params = new HttpParams();
        if (beginDate && endDate) {
            params = params.append('beginDate', beginDate).append('endDate', endDate);
        }
        return this.httpClient.get<InvoiceItem[]>(`${this.path}/invoice/client/${clientReference}`, {params: params});
    }

    appointmentSuggest(organizationCode: string, interventionSuggestRequest: InterventionSuggestRequest): Observable<InterventionSuggestionsResponse> {
        let params = new HttpParams();
        params = params.append('organizationCode', organizationCode);
        return this.httpClient.post<InterventionSuggestionsResponse>(`${GLOBAL.gatewayEndpoint}/back-office-service/repair/appointment/api/interventions/suggest`, interventionSuggestRequest, {params: params});

    }

    saveAppointment(organizationCode: string, interventionReservationRequest: InterventionReservationRequest): Observable<InterventionSuggestionsResponse> {
        let params = new HttpParams();
        params = params.append('organizationCode', organizationCode);
        return this.httpClient.post<InterventionSuggestionsResponse>(`${GLOBAL.gatewayEndpoint}/back-office-service/repair/appointment/api/interventions/`,
            interventionReservationRequest, {params: params});
    }

    deleteAppointment(appointmentId: string): Observable<any> {
        return this.httpClient.delete(`${GLOBAL.gatewayEndpoint}/back-office-service/repair/appointment/api/interventions/${appointmentId}`);
    }

    refund(refundRequest: RefundRequest): Observable<Folder> {
        return this.httpClient.put<Folder>(`${this.path}/payments/refund`, refundRequest);
    }

    request(folderId: string): Promise<RequestPaymentResponse> {
        return this.httpClient.post<RequestPaymentResponse>(`${this.path}/payments/${folderId}/request`, null).toPromise();
    }

    getCampaign(organizationCode: string, currentContext: string, currentBusinessLink: string): Observable<Campaign> {
        const queryParams = {organizationCode, currentContext, currentBusinessLink};
        return this.httpClient.get<Campaign>(`${this.path}/campaign/`, {params: queryParams});
    }

    getAddressesByStringSearch(searchString: any): Observable<any[]> {
        const queryParams = {searchString};
        return this.httpClient.get<any[]>(`${this.path}/localization/search`,
            {params: queryParams})
            .pipe(take(1));
    }

    computeGeolocation(address: Address): Observable<Location> {
        return this.httpClient.post<Location>(`${this.path}/localization/geo-locate`, address);
    }

    searchAllOriginatorsByCriterias(queryParams: PageSearchRequest, inputs: Map<string, string>): Observable<Page<Originator>> {
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<Originator>>(`${this.path}/originator?${requestParam}`, {params: <any>queryParams});
    }


    // Todo this endpoint will return only the first 10 Countries.
    bulkSearchRepairerDepartments(queryParams: PageSearchRequest, inputs: Map<string, string>): Observable<Page<IConfigRepairerDepartments>> {
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<IConfigRepairerDepartments>>(`${this.path}/configs/repairerDepartments/_search?${requestParam}`, {params: <any>queryParams});
    }

    getAvailableDates(organizationCode: string, serviceCode: string, productCode: string, warranty: string, reason: string, baseDate: string, context: string): Observable<any> {
        const queryParams = {context, productCode, warranty, reason, baseDate};
        return this.httpClient.get(`${this.path}/rules/v1/calendar/${organizationCode}/${serviceCode}/appointments/available-dates`, {params: queryParams});
    }

    getAvailableAppointments(organizationCode: string, serviceCode: string, productCode: string, warranty: string, reason: string, baseDate: string, context: string): Observable<any> {
        const queryParams = {context, productCode, warranty, reason, baseDate};
        return this.httpClient.get(`${this.path}/rules/v1/calendar/${organizationCode}/${serviceCode}/appointments`, {params: queryParams});
    }

    addAppointment(organizationCode: string, serviceCode: string, productCode: string, warranty: string, reason: string, appointmentProposal: AppointmentProposal, context: string): Observable<AppointmentProposal> {
        const queryParams = {context, productCode, warranty, reason};
        return this.httpClient.post<AppointmentProposal>(`${this.path}/rules/v1/calendar/${organizationCode}/${serviceCode}/appointments`, appointmentProposal, {params: queryParams});
    }

    getInstructions(ruleEvaluationContext: RuleEvaluationContext): Observable<Instructions> {
        return this.httpClient.post<Instructions>(`${this.path}/v4/instructions/best-match`, ruleEvaluationContext);
    }

    getRepairCenter(ruleEvaluationContext: RuleEvaluationContext, productLocation: string, customerZipCode?: string, customerCountryCode?: string): Observable<RepairCenter> {
        const queryParams = {productLocation};
        if (customerZipCode) {
            queryParams['customerZipCode'] = customerZipCode;
        }
        if (customerCountryCode) {
            queryParams['customerCountryCode'] = customerCountryCode;
        }
        return this.httpClient.post<RepairCenter>(`${this.path}/rules/v1/repairCenter`, ruleEvaluationContext, {params: queryParams});
    }


    bulkSearchExtraCodeLabelObjects(configCode: ConfigExtraCodesEnum, inputs: Map<string, string>, queryParams?: PageSearchRequest): Promise<Page<ICodeLabelObjectWithExtra<any>>> {
        const requestParam = HttpUtils.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<ICodeLabelObjectWithExtra<any>>>(`${this.path}/configs/item/extra/${configCode}/_search?${requestParam}`, {params: <any>queryParams}).toPromise();
    }

    deleteExtraCodeLabelObject(configCode: ConfigExtraCodesEnum, id: String): Promise<void> {
        return this.httpClient.delete<void>(`${this.path}/configs/item/extra/${configCode}/${id}`).toPromise();
    }

    getProductWarrantyRule(productWarrantyRequest: ProductWarrantyRequest): Observable<WarrantyRule> {
        return this.httpClient.post<WarrantyRule>(`${this.path}/rules/product/warranty`, productWarrantyRequest);
    }

    getSubElementWarrantyRule(productWarrantyRequest: ProductWarrantyRequest): Observable<WarrantyRule> {
        return this.httpClient.post<WarrantyRule>(`${this.path}/rules/product/warranty`, productWarrantyRequest);
    }

    getSubElements(ruleEvaluationRequest: RuleEvaluationContext): Observable<SubElement[]> {
        return this.httpClient.post<SubElement[]>(`${this.path}/v4/sub-elements/best-match`, ruleEvaluationRequest);
    }

    getAccessories(ruleEvaluationRequest: RuleEvaluationContext): Observable<ProductAccessory[]> {
        return this.httpClient.post<ProductAccessory[]>(`${this.path}/v4/accessories/best-match`, ruleEvaluationRequest);
    }

    getWarrantyReasonDetails(ruleEvaluationRequest: RuleEvaluationContext): Observable<WarrantyReasonDetails> {
        return this.httpClient.post<WarrantyReasonDetails>(`${this.path}/rules/warranty-detail`, ruleEvaluationRequest);
    }

    getAlertInfo(ruleEvaluationRequest: RuleEvaluationContext): Observable<AlertInfo[]> {
        return this.httpClient.post<AlertInfo[]>(`${this.path}/v4/claim-alerts/best-match`, ruleEvaluationRequest);
    }

    getWorkforces(ruleEvaluationRequest: RuleEvaluationContext): Observable<Workforce[]> {
        return this.httpClient.post<Workforce[]>(`${this.path}/configs/rules/workforces/best-match`, ruleEvaluationRequest);
    }

    getScopes(user: UserState): Observable<string[]> {
        let requestParams = new HttpParams();
        requestParams = requestParams.append('user', user.username);

        const functionalRoles = user.roles.filter(role => role.startsWith('ROLE'));
        functionalRoles.length === 0 ?
            requestParams = requestParams.append('role', 'any') :
            functionalRoles.forEach(role => requestParams = requestParams.append('role', role));

        return this.httpClient.get<string[]>(`${this.path}/configs/rules/scopes`, {
            params: requestParams
        });
    }

    fetchAvailableReport(claimId: string): Observable<ReportTemplate[]> {
        return this.httpClient.get<ReportTemplate[]>(`${this.path}/v1/report/templates/${claimId}`);
    }

    regenerateReport(reportRuleGenerated: ReportRuleGenerated): Observable<any> {
        return this.httpClient.post(`${this.path}/v1/report`, reportRuleGenerated);
    }

    fetchAvailableCommunication(claimId: string): Observable<NotificationCommandAudit[]> {
        return this.httpClient.get<NotificationCommandAudit[]>(`${this.path}/notification/audit/template/${claimId}`);
    }

    regenerateNotification(notificationCommandAudit: NotificationCommandAudit) {
        return this.httpClient.post(`${this.path}/notification/template/replay`, notificationCommandAudit);
    }
    computeProgressBarValue(stepDuration: string, currentStatusBeginDate: string): string {
        const statusEndDate = moment(currentStatusBeginDate).add(this.parseDuration(stepDuration), 'days');
        const durationToEndDate = moment.duration(statusEndDate.diff(currentStatusBeginDate));
        const totalHours = durationToEndDate.asHours();
        const durationToNow = moment.duration(statusEndDate.diff(moment()));
        const actualRemainingHours = durationToNow.asHours();
        return (((totalHours - actualRemainingHours) * 100) / totalHours).toFixed(1);
    }

    computeRemainingDays(stepDuration: string, currentStatusBeginDate: string): number {
        const statusEndDate = moment(currentStatusBeginDate).add(this.parseDuration(stepDuration), 'days');
        const durationToNow = moment.duration(statusEndDate.diff(moment()));
        return parseFloat(durationToNow.asDays().toFixed(0));
    }

    computeRemainingHours(stepDuration: string, currentStatusBeginDate: string): number {
        const statusEndDate = moment(currentStatusBeginDate).add(this.parseDuration(stepDuration), 'days');
        const durationToNow = moment.duration(statusEndDate.diff(moment()));
        return parseFloat(durationToNow.asHours().toFixed(0));
    }

    private parseDuration(workflowDuration: string): string {
        return workflowDuration.substring(workflowDuration.indexOf('P') + 1, workflowDuration.indexOf('D'));
    }

    showProgressBar(workflowDuration: string): boolean {
        return parseInt(this.parseDuration(workflowDuration), 0) < 99;
    }

    getCarriers(): Observable<Carrier[]> {
        return this.httpClient.get<Carrier[]>(`${this.path}/carriers`);
    }

    getInstructionUserTask(): Observable<InstructionUserTask[]> {
        return this.httpClient.get<InstructionUserTask[]>(`${this.path}/configs/instruction-user-task/all`);
    }

    getPermissions(): Observable<Role[]> {
        return this.httpClient.get<Role[]>( `${this.path}/configs/rules/permissions/v2/` );
    }

    getInstructionUserTaskByCode(code: string): Observable<InstructionUserTask> {
        return this.httpClient.get<InstructionUserTask>(`${this.path}/configs/instruction-user-task/${code}`);
    }

    computeSellingPrice(purchasePrice: number, defaultMargin: number, approximatePrice: number): Observable<Price> {
        let params = new HttpParams();
        if (purchasePrice) {
            params = params.append('purchasePrice', String(purchasePrice));
        }
        if (defaultMargin) {
            params = params.append('defaultMargin', String(defaultMargin));
        }
        if (approximatePrice) {
            params = params.append('approximatePrice', String(approximatePrice));
        }
        return this.httpClient.get<Price>(`${this.path}/v1/spare-parts/compute/sellingPrice`, {params: params});

    }

}

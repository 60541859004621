import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UploadFileDialogResponse} from '../../../../../../shared/upload-modal/upload.file.dialog.response';
import {ShowImageModalComponent} from '../../../../../image-modal/show-image-modal.component';
import {FileService} from '../../../../../../shared/services/file.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {Observable, of} from 'rxjs';
import {currency, currentLanguage, margin} from '../../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {debounceTime, distinctUntilChanged, map, startWith, take} from 'rxjs/operators';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {organizationCode} from '../../../../../../store/user/user.selectors';
import {SupplierDTO} from '../../../../../../models/spare-parts/supplier.model';
import {SparePartSupplierLink, SparePartView} from '../../../../../../models/spare-parts/spare-part.model';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {SingleUploadModalComponent} from '../../../../single-upload-modal/single-upload-modal.component';

@Component({
    selector: 'app-unreferenced-spare-part-modal',
    templateUrl: './unreferenced-spare-part-modal.component.html',
    styleUrls: ['./unreferenced-spare-part-modal.component.scss']
})
export class UnreferencedSparePartModalComponent extends Unsubscriber implements OnInit {

    form: FormGroup;
    attachmentsFiles = [];
    fileName: string;
    private organizationCode: string;
    currentLanguage$: Observable<string>;
    filteredSuppliers: Observable<SupplierDTO[]>;
    private currency: string;
    selectedSupplierOption = false;
    defaultMargin: number;
    selectedSupplier: SupplierDTO;
    filteredSuppliersDtos: SupplierDTO[];
    newPurchasePrice: number;
    isConfiguredMargin = false;
    unreferencedSparePart: SparePartView;
    initSupplierSelected = false;
    foundFromCatalog = false;

    constructor(private dialogRef: MatDialogRef<UnreferencedSparePartModalComponent>,
                private matDialog: MatDialog,
                private fileService: FileService,
                private sparePartService: SparePartService,
                private backOfficeService: BackOfficeService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public data) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => {
            this.defaultMargin = value;
        });
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.filteredSuppliers = new Observable<SupplierDTO[]>();
        this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(organizationCode => {
            this.organizationCode = organizationCode;
        });

        this.initUnreferencedSparePart();
        this.initSupplies();
    }

    initUnreferencedSparePart() {
        this.foundFromCatalog = false;
        this.form = new FormGroup({
            id: new FormControl(null),
            reference: new FormControl(this.data?.unreferencedSparePart?.code, [Validators.required]),
            label: new FormControl(this.data?.unreferencedSparePart?.label, [Validators.required]),
            ean: new FormControl(this.data?.unreferencedSparePart?.ean),
            purchasePrice: new FormControl(this.getInitialPurchasePrice(), [Validators.required, Validators.min(0)]),
            sellingPrice: new FormControl(this.getInitialSellingPrice(), [Validators.required, Validators.min(0)]),
            supplierCtrl: new FormControl(null, [Validators.required]),
            qte: new FormControl(this.data?.unreferencedSparePart?.quantity, [Validators.required, Validators.min(1)]),
            photo: new FormControl(null),
            supplierSearchInputCtrl: new FormControl(null),
            marginPercentage: new FormControl(this.getInitialMargin(), [Validators.required, Validators.min(0)])
        });
        if (this.data?.isUnderWarranty){
            this.form.addControl('sparePartFree', new FormControl(this.checkFreeSparePart(this.getInitSupplier())));
        }
        this.data?.unreferencedSparePart?.images?.filter(image => image.id).map(image => this.getSparePartsImages(image));
    }
    private getInitSupplier(){
        if (!this.data?.unreferencedSparePart) {
            return null;
        }

        return this.data.unreferencedSparePart.supplier
            ?? this.data.unreferencedSparePart.suppliers?.[0]
            ?? null;
    }

    get sparePartFreeControl(): FormControl {
        return this.form.controls.sparePartFree as FormControl;
    }

    private checkFreeSparePart(supplier): number {
        return this.data?.isUnderWarranty && supplier?.freeUnderWarranty;
    }
    changeSparePartFree(){
        if (this.sparePartFreeControl.value ){
            this.form.controls.sellingPrice.setValue(0);
        }else{
            this.computePriceUsingConfiguredMargin(this.form.controls.sellingPrice.value);
        }
    }
    private getInitialSellingPrice(): number {
        return !!this.data?.unreferencedSparePart?.sellingPrice ? this.data?.unreferencedSparePart?.sellingPrice.value : 0;
    }

    private selectInitialSupplier() {

        if (!!this.data?.unreferencedSparePart) {
            this.filteredSuppliersDtos = this.mapToSupplierDTOs(this.data.unreferencedSparePart.suppliers);
            this.filteredSuppliers = of(this.filteredSuppliersDtos);
            this.selectedSupplier = this.filteredSuppliersDtos?.[0];
            this.form.controls.supplierCtrl.setValue(this.selectedSupplier);
        }
    }
    private shouldSelectInitialSupplier(): boolean {
        return !!this.data?.unreferencedSparePart && !this.initSupplierSelected;
    }
    private getInitialMargin(): number {
        return !!this.data?.unreferencedSparePart?.marginPercentage ? this.data?.unreferencedSparePart?.marginPercentage : this.defaultMargin;
    }

    private getInitialPurchasePrice(): number {
        return !!this.data?.unreferencedSparePart?.purchasePrice ? this.data?.unreferencedSparePart?.purchasePrice : 0;
    }

    onPurchasePriceChanged(event) {
        this.newPurchasePrice = event.value || 0;
        this.computePriceUsingConfiguredMargin(this.form.controls.sellingPrice.value);
    }

    onSelectionSupplier(value) {
        this.selectedSupplierOption = true;
        this.selectedSupplier = value;
        if (!!this.selectedSupplier.purchasePrice){
            this.form.controls.purchasePrice.setValue(this.selectedSupplier.purchasePrice.value);
            this.computePriceUsingConfiguredMargin(this.form.controls.sellingPrice.value);

        }
       }

    private initSupplies(): void {
        this.anotherSubscription = this.form.get(['supplierSearchInputCtrl']).valueChanges
            .pipe(startWith(''),
                debounceTime(500),
                distinctUntilChanged(),
            )
            .subscribe(searchValue => {
                this.search(searchValue);
                if (this.shouldSelectInitialSupplier()) {
                    this.selectInitialSupplier();
                    this.initSupplierSelected = true;
                }
            });
    }

    search(searchValue: string) {
        this.filteredSuppliers = this.sparePartService.findSuppliers(0, 30, {code: searchValue, name: searchValue, matchingMode: 'ANY'})
            .pipe(
                map(page => {
                    return page.content;
                })
            );
    }

    onCloseModal(): void {
        this.dialogRef.close({confirmed: false});
    }

    validateForm(): void {
        this.store$.pipe(select(currency))
            .subscribe(currency => this.currency = currency);
        this.dialogRef.close({
            confirmed: true,
            foundFromCatalog: this.foundFromCatalog,
            foundedSparePartId: this.form.value.id,
            unreferencedSparePart:  this.getUnreferencedSparePartView(),
        });
    }

    private getUnreferencedSparePartView() {
        return {
            id: this.form.value.id,
            code: this.form.value.reference,
            label: this.form.value.label,
            suppliers: [{
                id: this.form.value.supplierCtrl.id,
                code: this.form.value.supplierCtrl.code,
                name: this.form.value.supplierCtrl.name,
                freeUnderWarranty: this.form.value.sparePartFree,
                unitPrice: {
                    value: this.form.value.purchasePrice,
                    currency: this.currency
                },
                supplierReference: this.form.value.reference
            }],
            ean: this.form.value.ean,
            approximatePrice: {
                value: this.form.value.approximatePrice,
                currency: this.currency
            },
            sellingPrice: {
                value: this.form.value.sellingPrice,
                currency: this.currency
            },
            purchasePrice: this.form.value.purchasePrice,
            marginPercentage: this.form.value.marginPercentage,
            quantity: this.form.value.qte,
            images: this.attachmentsFiles.map(it => {
                return {
                    id: it.id
                };
            }),

        };
    }

    showImageModal(srcImage): void {
        const dialogRef = this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px',
            data: [srcImage]
        });
    }

    onUploadImage(fileId?): void {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            disableClose: true,
            data: {
                folderId: this.data.folderId,
                organizationCode: this.data.organizationCode,
                uploadAttachment: true,
                hideFileType: true
            }
        });
        dialogRef.afterClosed().subscribe(
            (data: UploadFileDialogResponse) => {
                if (!!data) {
                    if (!!fileId) {
                        this.removeFileFromAttachment(fileId);
                    }
                    this.attachmentsFiles.push(data);
                    this.getAllAttachmentFiles();
                }
            }
        );
    }
    getAllAttachmentFiles(): void {
        this.attachmentsFiles.forEach(file => {
            this.fileService.getAttachmentFile(file.id).subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    file.src = reader.result;
                };
            });
        });
    }
    removeFileFromAttachment(fileId): void {
        if (!!fileId) {
            this.attachmentsFiles = this.attachmentsFiles.filter(attachment => attachment.id !== fileId);
        }
    }

    onReferenceChanges(event): void {
        const value = event.target.value;
        this.sparePartService.findWithCriteria(value, null)
            .pipe(take(1))
            .subscribe(existingSpareParts => {
                if (existingSpareParts.length > 0) {
                    this.initExistingUnreferencedSparePart(existingSpareParts[0]);
                } else {
                    this.initUnreferencedSparePart();
                    this.form.get(['reference']).setValue(value, {emitEvent: false});
                }
            });
    }

    private initExistingUnreferencedSparePart(sparePartView: any) {
        this.form.get(['id']).setValue(sparePartView.id);
        this.form.get(['reference']).setValue(sparePartView.code);
        this.form.get(['label']).setValue(sparePartView.label);
        this.form.get(['ean']).setValue(sparePartView.ean);
        this.filteredSuppliersDtos = this.mapToSupplierDTOs(sparePartView.suppliers);
        this.filteredSuppliers = of(this.filteredSuppliersDtos);
        if (this.filteredSuppliersDtos?.length > 0) {
            this.initSelectedSupplier(sparePartView);
            this.form.get(['supplierCtrl']).setValue(this.selectedSupplier);
            this.form.get(['purchasePrice']).setValue(sparePartView.suppliers?.[0].unitPrice?.value ?? 0);
            this.form.controls.sparePartFree?.setValue(this.checkFreeSparePart(this.selectedSupplier));
        }
        this.foundFromCatalog = true;
        const approximatePrice = !!sparePartView.approximatePrice ? sparePartView.approximatePrice.value : 0;
        this.computePriceUsingConfiguredMargin(approximatePrice);
        sparePartView.images?.filter(image => image.id).map(image => this.getSparePartsImages(image));
    }


    private initSelectedSupplier(sparePartView) {
        if (this.filteredSuppliersDtos.length > 0) {
            this.selectedSupplier = this.filteredSuppliersDtos.find(supplier => supplier.id === sparePartView.suppliers?.[0].id);
        } else {
            this.selectedSupplier = null;
        }
    }

    private mapToSupplierDTOs(sparePartSuppliers: SparePartSupplierLink[]) {
        return sparePartSuppliers?.map((element: SparePartSupplierLink) => ({
            id: element.id,
            name: element.name,
            code: element.code,
            purchasePrice: element.unitPrice,
            freeUnderWarranty: element.freeUnderWarranty
        }));
    }

    private getSparePartsImages(image) {
        this.fileService.getAttachmentFileAllInfo(image.id).subscribe(imageMetadata => {
            this.fileService.getAttachmentFile(image.id).subscribe(imageAsBlob => {
                const reader = new FileReader();
                reader.readAsDataURL(imageAsBlob);
                reader.onloadend = () => {
                    this.attachmentsFiles.push({
                        id: image.id,
                        name: imageMetadata.fileName,
                        uploadDate: imageMetadata.uploadDate,
                        src: reader.result
                    });
                };
            });
        });
    }

    public computePriceUsingConfiguredMargin(approximatePrice) {
        const purchasePrice = this.form.controls.purchasePrice.value;
        this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice || 0).subscribe(prices => {

            const warrantyOrSellingPrice = this.form.controls.sparePartFree?.value ? 0 : prices.sellingPrice || 0;
            this.form.controls.marginPercentage.setValue(prices.marginPercentage || 0);
            this.isConfiguredMargin = true;
            this.form.controls.sellingPrice.setValue(warrantyOrSellingPrice);
        });
    }

    public computePriceUsingGivenMargin() {
        const marginPercentage = this.form.controls.marginPercentage.value;
        const purchasePrice = this.form.controls.purchasePrice.value;
        const sellingPrice = this.calculateSellingPrice(purchasePrice, marginPercentage);
        this.form.controls.sellingPrice.setValue(sellingPrice);
    }

    private calculateSellingPrice(purchasePrice, marginPercentage) {
        return this.sparePartService.normalizeFloat((purchasePrice * (1 + (marginPercentage / 100))).toFixed(2));
    }

    calculateMarge() {
        const newPrice = this.form.controls.sellingPrice.value;
        const purchasePrice = this.form.controls.purchasePrice.value;
        const margin = this.getMargin(newPrice, purchasePrice);
        this.form.controls.marginPercentage.setValue(margin.toFixed(2));
    }

    private getMargin(sellingPrice: number, purchasePrice: number): number {
        return purchasePrice > 0 ? this.sparePartService.normalizeFloat((((sellingPrice / purchasePrice) - 1) * 100)) : 0;
    }


}

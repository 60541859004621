import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardV2Service} from '../../../../shared/services/dashboard-v2.service';
import {take, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {DashboardRule} from '../../../../models/dashboard/dashboard-rule.model';
import {LoadDashboard, SaveDashboard} from '../../../../store/dashboard/dashboard.actions';
import {BoldBI} from '@boldbi/boldbi-embedded-sdk';
import {Dashboard} from '../../../../models/dashboard/dashboard.model';
import {MatDialog} from '@angular/material/dialog';
import {AddEditDashboardDialogComponent} from '../add-edit-dashboard-dialog/add-edit-dashboard-dialog.component';

@Component({
    selector: 'edit-dashboard',
    template: '<div fxFlex id="edit-dashboard"></div>'
})
export class EditDashboardComponent implements OnInit, OnDestroy {

    private readonly containerId = 'edit-dashboard';

    dashboardId: string;
    dashboard: Dashboard;
    dashboardRule: DashboardRule;

    constructor(private dashboardService: DashboardV2Service,
                private activatedRoute: ActivatedRoute,
                private store$: Store<AppState>,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.dashboardService.findByRuleId(this.activatedRoute.snapshot.params.id)
            .pipe(take(1))
            .subscribe(dashboardRule => {
                this.dashboardRule = dashboardRule;
                this.dashboardId = dashboardRule.dashboard.dashboardId;
                this.store$.dispatch(new LoadDashboard({
                    embedContainerId: this.containerId,
                    mode: BoldBI.Mode.Design,
                    dashboardId: this.dashboardId,
                    onSave: this.saveDashboard
                }));
            });
    }

    ngOnDestroy() {
        const instance = BoldBI.getInstance(this.containerId);
        setTimeout(() => {
            instance?.destroyStyles();
        }, 1000);
    }

    saveDashboard = dashboardData => {
        this.dashboard = {
            ...this.dashboardRule.dashboard,
            dashboardName: dashboardData.dashboardName
        };
        this.dialog.open(AddEditDashboardDialogComponent, {
            data: {
                save: this.saveConfig,
                rule: this.dashboardRule
            },
            width: '30%',
            disableClose: true
        });
    }

    saveConfig = request => this.dashboardService.update({
        ...this.dashboardRule,
        ...request,
        dashboard: this.dashboard
    }).pipe(tap(() => this.store$.dispatch(new SaveDashboard(this.dashboardId))))

}

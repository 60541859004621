import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DashboardService} from 'app/shared/services/dashboard.service';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {currentUser} from 'app/store/user/user.selectors';
import {UserState} from '../../../../store/user/user.state';
import {AppState} from '../../../../store/app.state';
import {DashboardRule} from '../model/dashboard.model';
import {CodeLabel} from '../../../../models/element.model';
import {ConfigV2Service} from '../../../../shared/services/config-v2.service';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';

import {BackOfficeService} from '../../../../shared/services/back-office.service';

@Component({
    selector: 'app-add-charts-config-dialog',
    templateUrl: './add-edit-dashboard-dialog.component.html'
})
export class AddEditDashboardDialogComponent implements OnInit {
    chartsConfigForm: FormGroup;
    sites$: Observable<CodeLabel[]>;
    roles: any[] = [];
    user: UserState;

    constructor(public dialogRef: MatDialogRef<AddEditDashboardDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private store$: Store<AppState>,
                private configV2Service: ConfigV2Service,
                private backOfficeService: BackOfficeService,
                private dashboardService: DashboardService) {
    }


    ngOnInit(): void {
        this.createFormGroup();
        if (this.data.rule) {
            this.initForm();
        }
        this.sites$ = this.configV2Service.findAllValues(ConfigCodeMapsEnum.SITE).pipe(
            take(1),
            map(sites => [{id: '', code: '*', label: 'ALL', parent: ''}, ...sites])
        );
        this.backOfficeService.getPermissions().subscribe((data) => {
          this.roles =  Array.from(new Set(data.map(permission => permission.role)));
        });
        this.store$.pipe(select(currentUser), take(1))
            .subscribe(currentUser => this.user = currentUser);
    }

    createFormGroup() {
        this.chartsConfigForm = new FormGroup({
            site: new FormControl(),
            user: new FormControl('any'),
            role: new FormControl()
        });
    }

    initForm() {
        this.chartsConfigForm.patchValue(this.data.rule);
    }

    closeDialog() {
        this.dialogRef.close();
    }

    saveChartsConfigs() {
        const dashboardRuleRequest: DashboardRule = {
            context: this.user.context,
            organizationCode: this.user.organizationCode,
            user: this.getValueOrAny('user'),
            role: this.getValueOrAny('role'),
            site: this.getValueOrAny('site'),
            businessLink: 'any',
        };
        this.data.save(dashboardRuleRequest)
            .pipe(take(1))
            .subscribe(() => {
                this.closeDialog();
                this.dashboardService.showSuccessSave();
            }, () => this.dashboardService.showError());
    }

    getValueOrAny = field => this.chartsConfigForm.get(field).value ?? 'any';
    clearRoleForm = () => this.chartsConfigForm.get('role').reset();
}

export enum TaskVariables {
    notificationWorkflow = 'notificationWorkflow',
    trackingNumber = 'trackingNumber',
    shipmentErrorMessage = 'shipmentErrorMessage',
    shipmentMode = 'shipmentMode',
    productLocation = 'productLocation',
    sentToSupplier = 'sentToSupplier',
    paymentRequest = 'paymentRequest',
    symptomSelected = 'symptomSelected',
    survey = 'survey',
    resolutionSurvey = 'resolutionSurvey',
    confirmedDate = 'confirmedDate',
    canModifyWarranty = 'canModifyWarranty',
    canModifyCustomerAddress = 'canModifyCustomerAddress',
    canModifyAccessibility = 'canModifyAccessibility',
    wishedDatesWrapper = 'wishedDatesWrapper',
    panneConfirmed = 'panneConfirmed',
    canModifyConfirmedPanneComment = 'canModifyConfirmedPanneComment',
    closingDateTime = 'closingDateTime',
    site = 'site',
    interventionCustomerDetails = 'interventionCustomerDetails',
    surveyResponseId = 'surveyResponseId',
    managementSiteAppointment = 'managementSiteAppointment',
    repairCenterCode = 'repairCenterCode',
    carrierCode = 'carrierCode',
    fullRepairCenter = 'fullRepairCenter',
    quotationDraft = 'quotationDraft',
    quotation = 'quotation',
    quotationProcess = 'quotationProcess',
    totalQuotationPrice = 'totalQuotationPrice',
    warrantyCode = 'warrantyCode',
    confirmedAppointmentId = 'confirmedAppointmentId',
    rescheduleAppointment = 'rescheduleAppointment ',
    cancelAppointment = 'cancelAppointment',
    needSpareParts = 'needSpareParts',
    validateExchange = 'validateExchange',
    closingFolderReason = 'closingFolderReason',
    acceptRefundSupplier = 'acceptRefundSupplier',
    sendProduct = 'sendProduct',
    refundAgreementNumber = 'refundAgreementNumber',
    canSendFolderToRepairCenterFromFront = 'canSendFolderToRepairCenterFromFront',

    quotationId = 'quotationId',
    quotationSuggestionId = 'quotationSuggestionId',
    quotationDistributorId = 'quotationDistributorId',
    sparePartsOrderList = 'sparePartsOrderList',
    sparePartsWrapper = 'sparePartsWrapper',
    customerAbleToRepair = 'customerAbleToRepair',
    organizationCode = 'organizationCode',
    operatingModes = 'operatingModes',
    roomUrl = 'roomUrl',
    BeginDateAppointmentVisio = 'BeginDateAppointmentVisio',
    endDateAppointmentVisio = 'endDateAppointmentVisio',
    serviceType = 'serviceType',
    repairerNotConfirmed = 'repairerNotConfirmed',
    requestedServiceId = 'requestedServiceId',
    availableRepairPackages = 'availableRepairPackages',
    nomenclatures = 'nomenclatures',
    closingFolderReasonByDiagnostic = 'closingFolderReasonByDiagnostic',
    partnerLink = 'partnerLink',
    partnerName = 'partnerName',
    currency = 'currency',
    vat = 'vat',
    diagExpertMethod = 'diagExpertMethod',
    expertDiagnosticDecision = 'expertDiagnosticDecision',
    captureCustomerModOpChoice = 'captureCustomerModOpChoice',
    isSparePartsAvailable = 'isSparePartsAvailable',
    swapOption = 'swapOption',
    quotationAccepted = 'quotationAccepted',
    warrantyRejected = 'warrantyRejected',
    INSURANCE_PRICE = 'insurancePrice',
    HAS_INSURANCE = 'hasInsurance',
    FINAL_CUSTOMER_SOLUTION = 'finalCustomerSolution',
    CUSTOMER_VOUCHER_REASON = 'customerVoucherReason',
    DIAGNOSTIC_DECISION = 'diagnosticDecision',
    PROLONGED_DIAGNOSIS = 'prolongedDiagnosis',
    SUPPLIER_CONFIRMATION_CHANNEL = 'supplierConfirmationChannel',
    IS_PRODUCT_DANGER = 'isProductDanger',
    waitForReparationAgreement = 'waitForReparationAgreement'
}

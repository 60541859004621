import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BackOfficeService} from '../../../shared/services/back-office.service';
import {FolderService} from '../../../shared/services/folder.service';
import {Customer} from '../../../models/customer/customer.model';
import {FolderCreationService} from './folder-creation.service';
import {Invoice} from '../../../models/invoice.model';
import {Product, ProductState} from '../../../models/product.model';
import {forkJoin, Observable, Subject} from 'rxjs';
import {Folder, IFaultCode, IFolderCreateRequestSnapshot} from '../../../models/folder.model';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {SiteType} from '../../../models/enums/siteType.enum';
import {OriginEnum} from '../../../models/enums/origin.enum';
import {MatDialog} from '@angular/material/dialog';
import {Context} from '../../../models/enums/context.enum';
import {WarrantyReasonEnum} from '../../../models/enums/warrantyReason.enum';
import {finalize, switchMap, take} from 'rxjs/operators';
import {ProductWarrantyRequest, RegimeWarrantyEnum} from '../../../models/warrantyRule.model';
import {CommentType} from '../../../models/enums/CommentType.enum';
import {OldFolderReparationsComponent} from './old-folder-reparations/old-folder-reparations.component';
import {CustomerType} from '../../../models/enums/CustomerType.enum';
import {ShowImageModalComponent} from '../../image-modal/show-image-modal.component';
import {Alert, AlertDto, AlertInfo} from '../../../models/Alert.model';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {SubElement} from '../../../models/SubElement';
import {ProductAccessory} from '../../../models/ProductAccessory';
import {Constants} from '../../../Constants';
import {AppState} from 'app/store/app.state';
import {select, Store} from '@ngrx/store';
import {currentUser, hasInvoiceHint, invoiceHint} from '../../../store/user/user.selectors';
import {UserState} from '../../../store/user/user.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {Unsubscriber} from '../../../unsubscriber';
import {FormControl} from '@angular/forms';
import {SharedService} from '../../../shared/services/shared.service';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../models/enums/productFamilyType.enum';
import {customer, isCreationWithoutCustomer, isCustomerFormValid, isStockClaimCreation} from '../../../store/customer/customer.selectors';
import {ResetCustomer, ResetCustomerSearchResult, SetCreationWithoutCustomer} from '../../../store/customer/customer.actions';
import {Supplier} from '../../../models/supplier.model';
import {AlertFolderModalComponent} from '../folder-snapshot-card/alert-folder-modal/alert-folder-modal.component';
import {PhoneNumberPipe} from '../../../../@fuse/pipes/phone-number.pipe';
import libphonenumber from 'google-libphonenumber';
import {countryCode} from '../../../store/organization/organization.selectors';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;
import {GrowthbookAttributes} from '../../../shared/features/growthbook-attributes';
import {AppFeatures} from '../../../shared/features/app-features';
import {GrowthbookService} from '../../../shared/services/growthbook.service';

@Component({
    selector: 'app-create-folder',
    templateUrl: './create-folder.component.html',
    styleUrls: ['./create-folder.component.scss'],
    providers: [DatePipe]
})
export class CreateFolderComponent extends Unsubscriber implements OnInit, AfterViewInit, AfterViewChecked {

    public readonly PAGE_SIZE: number = 10;

    public clearSearch$: Subject<boolean> = new Subject<boolean>();
    suppliers: Supplier[];
    constructor(
        private phoneNumberPipe: PhoneNumberPipe,
                private backOfficeService: BackOfficeService,
                private folderService: FolderService,
                private folderCreationService: FolderCreationService,
                private store$: Store<AppState>,
                private alertSubjectService: PushNotificationSubjectService,
                private router: Router,
                private datePipe: DatePipe,
                private dialog: MatDialog,
                private sharedService: SharedService,
                private growthBookService: GrowthbookService,
                private cdRef: ChangeDetectorRef) {
        super();
    }
    searchForm = {
        invoice: '',
        product: '',
        productLabel: '',
        ean: '',
        manufacturerReference: '',
        client: '',
        supplier: Supplier
    };
    customer: Customer;
    invoice: Invoice;
    product: Product;
    currentUser: UserState;
    hasInvoiceHint$: Observable<boolean>;
    isSearchProductPerLabelActive = false;
    isPurchaseDateRequired = false;
    productSelected = false;
    productNotFound = false;
    showProductState = false;
    invoiceNotFound = false;
    createFolderInProgress = false;
    isCustomerFormValid: boolean;
    isPhoneValid: boolean;
    private folderSymptom: IFaultCode;
    isFolderCreationWithoutCustomer: boolean;
    isStockClaimCreation: boolean;
    quantity: number;
    comment: string;
    productState: ProductState = new ProductState();

    subElements: SubElement[] = [];
    selectedSubElement: SubElement;
    unkwonSupplierCode: boolean;

    accessories: ProductAccessory[] = [];
    selectedAccessories: ProductAccessory[] = [];
    identificationProductCtrl = new FormControl();

    invoiceItemLineNumber: number;

    @Input() clearInvoice: Subject<boolean> = new Subject<boolean>();

    countryCode: string;
    customerPreferredLanguage: string;

    folderCreationStockOptions: any;

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(countryCode => this.countryCode = countryCode);
        this.hasInvoiceHint$ = this.store$.pipe(select(hasInvoiceHint));
        this.initFeatureOptions();
        this.anotherSubscription = this.folderCreationService.productQuantity$.subscribe(quantity => {
            this.quantity = quantity;
        });
        this.anotherSubscription = this.store$.pipe(select(customer)).subscribe(customer => {
            this.customer = customer;
        });
        this.anotherSubscription = this.store$.pipe(select(isCustomerFormValid)).subscribe(isValid => {
            this.isCustomerFormValid = isValid;
        });
        this.anotherSubscription = this.folderCreationService.invoice$.subscribe(invoice => {
            this.invoice = invoice;
            if (this.product?.code && !this.isProductRecallContext()) {
                this.getProductWarranty();
                this.getSubElementsAndAccessories();
            }
        });
        this.anotherSubscription = this.folderCreationService.getNewProduct().subscribe(product => {
            this.product = product;
            this.unkwonSupplierCode = this.product?.supplier?.code === Constants.UNKNOWN_SUPPLIER;
            if (this.product?.code && !this.isProductRecallContext()) {
                this.getProductWarranty();
                this.getSubElementsAndAccessories();
            }
        });
        this.anotherSubscription = this.folderCreationService.productFromInvoice$.subscribe(product => {
            this.productSelected = true;
            this.product = product;
            this.unkwonSupplierCode = this.product?.supplier?.code === Constants.UNKNOWN_SUPPLIER;
            if (this.product?.code && !this.isProductRecallContext()) {
                this.getProductWarranty();
                this.getSubElementsAndAccessories();
            }
        });

        this.anotherSubscription = this.folderCreationService.customerPreferredLanguage$.subscribe(customerPreferredLanguage => {
            this.customerPreferredLanguage = customerPreferredLanguage;
        });

        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(currentUser => {
            this.currentUser = currentUser;
            this.cleanAllForms();

            if (this.isProductRecallContext()) {
                this.anotherSubscription = this.backOfficeService.getCampaign(this.currentUser.organizationCode,
                    this.currentUser.context, this.currentUser.businessLink).subscribe(campaign => {
                    campaign.products.forEach(p => p.afterSalesEligible = true);
                    this.folderCreationService.productListChanged(campaign.products);
                    this.folderSymptom = campaign.faultCode;
                });
            }
        });

        this.anotherSubscription = this.store$.pipe(select(isCreationWithoutCustomer))
            .subscribe(isCreationWithoutCustomer => this.isFolderCreationWithoutCustomer = isCreationWithoutCustomer);

        this.anotherSubscription = this.store$.pipe(select(isStockClaimCreation))
            .subscribe(isStockClaim => {
                this.isStockClaimCreation = isStockClaim;
                this.isPurchaseDateRequired = this.isStockClaimCreation ? this.folderCreationStockOptions?.isPurchaseDateMandatoryInStockFolderCreation : false;
            });

        this.sharedService.isMobilePhoneInputValid()
            .subscribe(isValid => this.isPhoneValid = isValid);

        this.anotherSubscription = this.folderCreationService.lineNumber$.subscribe(lineNumber => {
            this.invoiceItemLineNumber = lineNumber;
        });
    }

    private initFeatureOptions() {
        this.anotherSubscription = this.store$.pipe(
            select(currentUser),
            take(1),
            switchMap((user: UserState) => {
                if (user) {
                    const attributes: GrowthbookAttributes = {
                        organizationCode: user.organizationCode,
                        context: user.context

                    };
                    return this.growthBookService.getFeatureValue(AppFeatures.FOLDER_CREATION_FIELDS_OPTIONS, attributes, null);
                }
            })
        ).subscribe((folderCreationOptions: any) => {
            this.folderCreationStockOptions = folderCreationOptions.inStock;
            this.isSearchProductPerLabelActive = folderCreationOptions.isSearchProductPerLabelActive;
        });
    }

    resetProductStateInChild() {
        this.productState = new ProductState();
        this.showProductState = false;
        this.searchForm.invoice = '';
        this.invoice = new Invoice();
        this.clearInvoice.next(true);
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }
    searchWithInvoice(dontRetry: boolean): void {
        this.folderCreationService.productListIsFilteredResult(false);
        this.searchForm.product = null;
        this.invoiceNotFound = false;
        this.productNotFound = false;
        this.showProductState = false;

        if (dontRetry && this.isInvoiceSearch()) {
            return;
        }

        this.searchForm.invoice = this.searchForm.invoice.replace(/\s/g, '');

        if (this.searchForm.invoice.length > 0) {
            this.showProductState = false;
            this.folderCreationService.productChanged(null);
            this.store$.dispatch(new StartLoading());

            this.backOfficeService.searchInvoice(this.searchForm.invoice).subscribe(invoice => {
                if (invoice === null) {
                    this.invoice = new Invoice();

                } else {
                    this.invoice = invoice;
                    this.backOfficeService.searchInvoiceProducts(this.searchForm.invoice, '0', '5')
                        .subscribe(data => {
                            if (data.totalElements === 1) {
                                this.folderCreationService.productFromInvoiceChanged(data.content[0]);
                                this.folderCreationService.productChanged(data.content[0]);
                                this.showProductState = true;
                            }
                            this.folderCreationService.invoiceChanged(this.invoice);
                            this.folderCreationService.invoiceProductsPageChanged(data);
                            this.store$.dispatch(new StopLoading());
                        });
                }
            }, () => {
                this.store$.dispatch(new StopLoading());
                this.invoiceNotFound = true;
            });
        }
    }




    createFolder(): void {
        this.store$.dispatch(new StartLoading());
        this.createFolderInProgress = true;
        const folderCreateRequest = this.prepareFolderCreateRequest();
        if (this.currentUser.context === Context.SAV && this.isInvoicePresent()) {
            this.folderService.getOldReparations(this.invoice.id, folderCreateRequest.purchaseInvoice.invoiceLine, this.product.code).subscribe(folders => {
                if (folders && folders.length > 0) {
                    this.store$.dispatch(new StopLoading());
                    this.dialog.open(OldFolderReparationsComponent, {
                        minWidth: 800,
                        data: folders
                    }).afterClosed().subscribe(data => {
                        if (data && data.selected) {
                            this.createFolderFomOldReparation(folderCreateRequest, data.selected);
                        } else if (data && data.createNew) {
                            this.callCreateFolder(folderCreateRequest);
                        }
                        this.createFolderInProgress = false;
                    });
                } else {
                    this.callCreateFolder(folderCreateRequest);
                }
            }, () => {
                this.store$.dispatch(new StopLoading());
                this.createFolderInProgress = false;
            });
        } else {
            this.callCreateFolder(folderCreateRequest);
        }
    }


    computeSiteCode(site): string {
        if (site && typeof site === 'object') {
            return site.code;
        }
        if (typeof site === 'string') {
            return site;
        }
        return this.currentUser.affectedSite;
    }

    private prepareFolderCreateRequest(): IFolderCreateRequestSnapshot {
        const mobileNumber = this.phoneNumberPipe.getFormattedMobilePhone(this.customer?.contact?.mobileNumber, this.countryCode, PhoneNumberFormat.INTERNATIONAL).mobileNumber;
        const phoneNumber = this.phoneNumberPipe.getFormattedPhone(this.customer?.contact?.phoneNumber, this.countryCode, PhoneNumberFormat.INTERNATIONAL).phoneNumber;

        const contact = {
            ...this.customer.contact,
            mobileNumber: mobileNumber,
            phoneNumber: phoneNumber
        };

        this.customer = {
            ...this.customer,
            contact: contact,
            preferredLanguage: this.customerPreferredLanguage,
            addresses: this.customer.address ? [this.customer.address] : []
        };
        this.product.productState = this.productState.state;
        const folderCreateRequest: IFolderCreateRequestSnapshot = {
            customer: <Customer>this.customer,
            product: this.product,
            origin: OriginEnum.BO,
            stockClaim: this.isStockClaimCreation,
            context: this.currentUser.context,
            businessLink: this.currentUser.businessLink ? this.currentUser.businessLink : null,
            organization: {
                code: this.currentUser.organizationCode, label: this.currentUser.organizationCode, id: '',
            },
            quantity: this.quantity,
            purchaseInvoice: {
                number: this.invoice.id || 'N/C',
                invoiceLine:  String(this.product['lineNumber'] || this.invoiceItemLineNumber || 1),
                sellingPrice: this.product['sellingPrice'],
                quantity: this.product['quantity'] ? this.product['quantity'] : 1,
                underWarranty: this.product['underWarranty'] ? this.product['underWarranty'] : null,
                purchaseDate: this.computePurchaseDate(),
                endOfWarrantyDate: this.product['endOfWarrantyDate'] ? this.product['endOfWarrantyDate'] : null,
                site: this.computeSiteCode(this.invoice.siteCode)
            },
            site: {
                code: this.currentUser.affectedSite,
                type: SiteType.CREATION_SITE
            },
            attachments: this.productState.stateAttachments,
        };
        this.setSubElements(folderCreateRequest);
        this.setAccessories(folderCreateRequest);
        this.setAgentComment(folderCreateRequest);
        if (this.currentUser.context === Context.PRODUCT_RECALL) {
            this.handleProductRecallFolder(folderCreateRequest);
        }
        return folderCreateRequest;
    }

    public computePurchaseDate(): string {
        return this.datePipe.transform(this.invoice?.creationDate, 'yyyyMMdd') || '19900101';
    }

    private callCreateFolder(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        this.store$.dispatch(new StartLoading());
        this.folderService.createFolder(folderCreateRequest).subscribe(folder => {
            this.showAlertBulletinModal(folder);
        }, () => {
            this.store$.dispatch(new StopLoading());
            this.createFolderInProgress = false;
        });
    }

    showAlertBulletinModal(folder): void {
        this.router.navigate(['folder', folder.id])
            .then(() => this.getAlertInfo(folder))
            .finally(() => this.store$.dispatch(new StopLoading()));
    }

    getAlertInfo(folder: Folder): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(folder);

        this.backOfficeService.getAlertInfo(ruleEvaluationContext)
            .subscribe(value => {
                    if (!!value && value.length > 0) {
                        this.dialog.open(AlertFolderModalComponent, {
                            minWidth: 1024,
                            data: {
                                alerts: value,
                                showButton: true,
                            },
                            disableClose: true
                        });
                        this.saveAlert(folder, value);
                        this.alertSubjectService.refreshAlert(new Alert());
                    }
                }
            );
    }

    private saveAlert(folder: Folder, alertsInfo: AlertInfo[]) {
        const folderAlerts = alertsInfo.map(alertInfo => ({
            businessId: folder.id,
            content: alertInfo.content,
            attachmentIds: alertInfo.attachmentIds
        } as AlertDto));
        this.folderService.saveAlertsInfo(folderAlerts);
    }

    private createFolderFomOldReparation(folderCreateRequest: IFolderCreateRequestSnapshot, oldFolder: Folder): void {
        this.store$.dispatch(new StartLoading());
        this.completeFolderCreateRequestWithOldReparationInfo(oldFolder, folderCreateRequest);
        this.folderService.createFolderFomOldReparation(folderCreateRequest).subscribe(folder => {
            this.showAlertBulletinModal(folder);
        }, () => {
            this.store$.dispatch(new StopLoading());
            this.createFolderInProgress = false;
        });
    }

    private completeFolderCreateRequestWithOldReparationInfo(oldFolder: Folder, folderCreateRequest: IFolderCreateRequestSnapshot): void {
        if (oldFolder.reparationReport && oldFolder.reparationReport.iris && oldFolder.reparationReport.iris.length > 0) {
            folderCreateRequest.faultCode = {
                code: oldFolder.reparationReport.iris[0].symptom.code,
                label: oldFolder.reparationReport.iris[0].symptom.label,
                condition: ''
            };
        } else {
            folderCreateRequest.faultCode = oldFolder.faultCode;
        }
        folderCreateRequest.oldReparationFolder = oldFolder.id;
    }

    private handleProductRecallFolder(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        folderCreateRequest.warranty = {
            warranty: RegimeWarrantyEnum.SG,
            warrantyReason: WarrantyReasonEnum.PRODUCT_RECALL
        };
        folderCreateRequest.faultCode = this.folderSymptom;
        folderCreateRequest.site = {
            code: 'ALL',
            name: 'Centrale',
            address: {
                countryCode: 'FR',
                address: '70 Rue Elie Cartan',
                city: 'Carvin',
                zipCode: '62220',
                countryName: 'France',
                location: {
                    lon: '50.4730629263259',
                    lat: '2.95794246167126'
                }
            },
            type: SiteType.CREATION_SITE
        };
    }

    isCreateFolderEnabled(): any {
        if (this.currentUser.context === Context.PRODUCT_RECALL) {
            return this.isProductValid() && !this.createFolderInProgress;
        }
        if (this.isFolderCreationWithoutCustomer) {
            return this.isProductValid() && !this.createFolderInProgress;
        }
        if (this.isStockClaimCreation) {
            return this.isProductValid() && !this.createFolderInProgress  && this.isPurchaseDateValid();
        }
        return this.isCustomerPresent() &&
            this.isCustomerFormValid &&
            this.isPhoneValid &&
            this.isProductValid() &&
            !this.createFolderInProgress;
    }

    private isCustomerPresent(): any {
        let customerCommunDetails;
        if (this.currentUser.context !== Context.SAV) {
            customerCommunDetails = !!(this.customer && this.customer.firstName && this.customer.lastName && this.customer.gender
                && this.customer.contact && this.customer.contact.email && this.customer.contact.mobileNumber
                && this.customer.addresses
                && this.customer.addresses.length > 0
                && this.customer.addresses[0].address
                && this.customer.addresses[0].city
                && this.customer.addresses[0].zipCode
                && this.customer.addresses[0].countryCode);
        } else {
            customerCommunDetails = this.customer?.firstName && this.customer?.lastName;
        }
        if (this.customer?.type && this.customer.type === CustomerType.PRIVATE_INDIVIDUAL) {
            return customerCommunDetails;
        }
        return customerCommunDetails && !!this.customer.company && !!this.customer.company.label && !!this.customer.company.legalForm;
    }




    public isProductSelected = () =>
        this.product
        && this.product.code
        && (this.product.afterSalesEligible || this.currentUser.context === Context.PRODUCT_RECALL)

    private isProductValid = () =>
        this.product
        && this.product.code
        && this.quantity > 0
        && (!this.isGenericProduct() || this.isGenericProductValid())
        && (this.product.afterSalesEligible || this.currentUser.context === Context.PRODUCT_RECALL)

    private isGenericProductValid = () =>
        this.product.supplier.code !== Constants.UNKNOWN_SUPPLIER
        && this.product.purchasePrice && this.product.purchasePrice.value
        && this.product.label

    private isGenericProduct = () => {
        const organizationCode = this.currentUser.organizationCode;
        return (organizationCode.startsWith('LM') || organizationCode === 'BC_IT') && this.unkwonSupplierCode;
    }


    cleanAllForms(): void {
        this.resetClient();
        this.folderCreationService.invoiceChanged(new Invoice());
        this.folderCreationService.productChanged(new Product());
        this.folderCreationService.productListChanged(null);
        this.searchForm = {
            client: '', invoice: '', product: '', productLabel: '', ean: '', manufacturerReference: '',   supplier: Supplier
        };
        this.store$.dispatch(new SetCreationWithoutCustomer(false));
        this.productSelected = false;
        this.showProductState = false;
        this.identificationProductCtrl.setValue(null);
        this.anotherSubscription = this.clearSearch$.next(true);
    }

    resetClient(): void {
        this.searchForm.client = '';
        this.store$.dispatch(new ResetCustomer());
        this.store$.dispatch(new ResetCustomerSearchResult());
    }

    getProductWarranty(): void {
        this.store$.dispatch(new StartLoading());
        const productWarrantyRequest = this.buildProductWarrantyRequest();
        this.backOfficeService.getProductWarrantyRule(productWarrantyRequest)
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(productWarranty => this.folderCreationService.productWarrantyChanged(productWarranty));
    }

    private getSubElementsAndAccessories(): void {
        this.store$.dispatch(new StartLoading());
        this.subElements = [];
        this.accessories = [];
        const ruleEvaluationContext = RuleEvaluationContext.fromProduct(this.product, this.currentUser);

        const subElements = this.backOfficeService.getSubElements(ruleEvaluationContext);
        const accessories = this.backOfficeService.getAccessories(ruleEvaluationContext);
        forkJoin([subElements, accessories])
            .pipe(finalize(() => this.store$.dispatch(new StopLoading())))
            .subscribe(combines => {
                this.subElements = combines[0];
                this.accessories = combines[1];
            });
    }

    private buildProductWarrantyRequest(): ProductWarrantyRequest {
        return {
            organizationCode: this.currentUser.organizationCode,
            context: this.currentUser.context,
            productCode: this.product.code,
            invoiceNumber: this.invoice.id,
            invoiceLine: this.product['lineNumber'],
            purchaseDate: this.computePurchaseDate(),
            invoiceEndOfWarrantyDate: this.product['endOfWarrantyDate'],
            family: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.FAMILY),
            subFamily: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SUB_FAMILY),
            type: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.TYPE),
            subType: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SUB_TYPE),
            segment: RuleEvaluationContext.getFamilyByType(this.product.families, ProductFamilyType.SEGMENT),
            brand: this.product.brand,
            supplierCode: this.product.supplier?.code,
            productWarranty : this.product.warranty
        };
    }

    private setSubElements(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        if (!!this.selectedSubElement) {
            folderCreateRequest.productSubElement = this.selectedSubElement;
        }
    }

    private setAccessories(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        if (this.selectedAccessories.length > 0) {
            folderCreateRequest.productAccessories = this.selectedAccessories;
        }
    }

    private setAgentComment(folderCreateRequest: IFolderCreateRequestSnapshot): void {
        if (!!this.comment) {
            this.comment = this.comment.trim(); // sanitize the comment
        }

        if (!!this.comment) {
            folderCreateRequest.agentComment = {
                content: this.comment,
                type: CommentType.CLAIM_CREATION_COMMENT
            };
        }
    }

    isProductRecallContext(): boolean {
        return this.currentUser.context === Context.PRODUCT_RECALL;
    }

    handleBackButton($event: any): void {
        this.productSelected = false;
        this.product = null;
        this.subElements = [];
        this.selectedSubElement = null;
        this.accessories = [];
        this.selectedAccessories = [];
        this.searchWithInvoice(false);
        this.folderCreationService.productListIsFilteredResult(!this.isInvoicePresent());
    }


    private isInvoiceSearch() {
        return this.invoice && this.invoice.id === this.searchForm.invoice;
    }

    private isInvoicePresent(): boolean {
        return !!this.invoice && !!this.invoice.id;
    }

    changeShowProductState(event): void {
        this.showProductState = event;

    }

    showImageModal(): void {
        const invoicePhoto = this.store$.pipe(select(invoiceHint));
        this.dialog.open(ShowImageModalComponent, {
            height: '80%',
            width: '70vh',
            minWidth: '70%',
            data: invoicePhoto
        });
    }

    getSelectedSubElement($event: SubElement): void {
        this.selectedSubElement = $event;
    }

    getSelectedAccessories($event: ProductAccessory[]): void {
        this.selectedAccessories = $event;
    }

    sendProductNotFound($event: boolean) {
        this.productNotFound = $event;
    }

    setShowProductState($event){
        this.showProductState = $event;
    }

    private isPurchaseDateValid(): boolean {
        return this.isPurchaseDateRequired && !!this.invoice?.creationDate || !this.isPurchaseDateRequired;
    }
}


import {Injectable} from '@angular/core';
import {CodeLabel} from '../../app/models/element.model';
import {ConfigV2Service} from '../../app/shared/services/config-v2.service';
import {map, skipWhile} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {currentLanguage} from '../../app/store/organization/organization.selectors';

@Injectable({
    providedIn: 'root'
})
export class CodeToLabelService {

    private codeLabels: Map<string, CodeLabel[]> = new Map<string, CodeLabel[]>();

    constructor(private configV2Service: ConfigV2Service,
                private store$: Store<AppState>) {
        this.currentLanguageChanged();
    }

    public clear() {
        this.codeLabels.clear();
    }

    public setCodeLabels(codeConfig: string, codeLabels: CodeLabel[]): void {
        this.codeLabels.set(codeConfig, codeLabels);
    }

    public getLabelOfCode(codeConfig: string, code: string): Observable<string> {
        const codeLabel = this.codeLabels.get(codeConfig)?.find((cl) => cl.code === code);
        if (codeLabel) {
            return of(codeLabel.label);
        }
        return this.configV2Service.findLabelOfCode(codeConfig, code).pipe(
            map((codeLabel: CodeLabel) => {
                const label = codeLabel ? codeLabel.label : code;
                const existingLabels = this.codeLabels.get(codeConfig) || [];
                this.codeLabels.set(codeConfig, [...existingLabels, { code, label }]);
                return label;
            })
        );
    }


    public getCodeLabels(codeConfig: string): Observable<CodeLabel[]> {
        const cachedLabels = this.codeLabels.get(codeConfig);
        if (cachedLabels) {
            return of(cachedLabels);
        }
        return this.configV2Service.findLocalizedValuesOf([codeConfig]).pipe(
            map((response: CodeLabel[]) => {
                this.setCodeLabels(codeConfig, response);
                return response;
            })
        );
    }

    private currentLanguageChanged(){
        this.store$.pipe(select(currentLanguage), skipWhile(language => !language))
            .subscribe(currentLanguage => {
                this.codeLabels.clear();
            });
    }

}

import {AbstractEntity, IAbstractEntity} from './abstractEntity.model';
import {Organization} from './organization.model';
import {Product} from './product.model';
import {Address, Customer} from './customer/customer.model';
import {ISurvey} from './survey.model';
import {FolderOrigin} from './enums/folderOrigin.enum';
import {CustomerChoiceEnum, OperationModeEnum} from './enums/operationMode.enum';
import {Status} from './status.model';
import {Comment} from './comment.model';
import {FileInfo} from './file-info.model';
import {Site} from './site.model';
import {IWarranty} from './newWarranty.model';
import {IPurchaseInvoice} from './purchaseInvoice.model';
import {CommentType} from './enums/CommentType.enum';
import {Immobilization} from './immobilization.model';
import {Quotation} from './quotation.model';
import {Appointment} from './appointment.model';
import {PaymentReason} from './enums/paymentReason.enum';
import {PaymentMode} from './enums/PaymentMode';
import {Shipment} from './shipment.model';
import {OriginEnum} from './enums/origin.enum';
import {ReparationReport} from './reparationReport/reparation-report.model';
import {PayerModel} from './payer.model';
import {ServiceType} from './enums/serviceType.enum';
import {StatusInfo} from './StatusInfo';
import {PendingStatus} from './pendingStatus.model';
import {ProductAccessory} from './ProductAccessory';
import {Amount} from './amount.model';
import {ProductSubElement} from './product-sub-element';
import {UserAction} from './userAction.model';
import {ShipmentItem} from './shipmentItem.model';
import {ShipmentUpdateRequest} from './shipment-update-request';

interface IFolder extends IAbstractEntity {
    id: string;
    reference: string;
    incrementalReference: number;
    serialNumber1: string;
    serialNumber2: string;
    advance: number;
    immobilizationDays: number;
    newWarranty: IWarranty;
    warrantyOrigin: IWarranty;
    folderOrigin: FolderOrigin;
    operationMode: OperationModeEnum;
    productState: string;
    product: Product;
    organization: Organization;
    customer: Customer;
    isStockClaim: boolean;
    faultCode: IFaultCode;
    attachments: FileInfo[];
    survey: ISurvey;
    currentStatus: Status;
    statusHistory: Status[];
    sites: Site[];
    purchaseInvoice: IPurchaseInvoice;
    repairCenter: any;
    stockClaim: boolean;
    appointment: any;
    externalReferences: Map<string, string>;
    customerComments: Map<CommentType, Comment[]>;
    agentComments: Map<CommentType, Comment[]>;
    payments: Payment[];
    quotations: Quotation[];
    shipments: Shipment[];
    origin: OriginEnum;
    tags?: string[];
    payers: PayerModel;
    quantity: number;

}

export class WorkOrder {
    id: string;
    number?: string;
    createdDate: string;
}

export class Folder extends AbstractEntity implements IFolder {
    id: string;
    reference: string;
    incrementalReference: number;
    serialNumber1: string;
    serialNumber2: string;
    advance: number;
    immobilizationDays: number;
    status: string;
    warrantyOrigin: IWarranty;
    newWarranty: IWarranty;
    folderOrigin: FolderOrigin;
    operationMode: OperationModeEnum;
    oldOperationMode: OperationModeEnum;
    oldOperationModes: OperationMode[];
    productState: string;
    product: Product;
    organization: Organization;
    customer: Customer;
    isStockClaim: boolean;
    faultCode: IFaultCode;
    attachments: FileInfo[];
    survey: ISurvey;
    autoRepairSurvey: ISurvey;
    currentStatus: Status;
    statusInfo: StatusInfo;
    pendingStatus: PendingStatus;
    statusHistory: Status[];
    sites: Site[];
    purchaseInvoice: IPurchaseInvoice;
    repairCenter: any;
    stockClaim: boolean;
    appointment: AppointmentWrapper;
    externalReferences: Map<string, string> = new Map();
    customerComments: Map<CommentType, Comment[]> = new Map<CommentType, Comment[]>();
    agentComments: Map<CommentType, Comment[]> = new Map<CommentType, Comment[]>();
    repairerComments: Map<CommentType, Comment[]> = new Map<CommentType, Comment[]>();
    supplierComments: Map<CommentType, Comment[]> = new Map<CommentType, Comment[]>();
    currentWorkflowStatus: ICurrentWorkflowStatus;
    quotations: Quotation[];
    immobilization: Immobilization;
    payments: Payment[];
    shipments: Shipment[];
    origin: OriginEnum;
    tags?: string[];
    context: string;
    businessLink: string;
    reparationReport: ReparationReport;
    originator: Customer;
    payers: PayerModel;
    quantity: number;
    services: Service[];
    productSubElement: ProductSubElement;
    productAccessories: ProductAccessory[];
    customerChoice: CustomerChoiceEnum;
    workOrders: WorkOrder[];
    shortUrl: string;
    orderIds: string[];
    version: number;
    externalUid: any;
    warrantyEndDate: string;
}

export class RepairCenter {
    code: string;
    label: string;
    interventionDepartments: InterventionDepartments[];
    contact?: any;
    completeAddress?: Address;
    publicAgenda?: boolean;
    organization?: string;
}
export class AddressRepairer {
    contact?: any;
    completeAddress?: Address;
}
export class InterventionDepartments {
    departmentCode: string;
    departmentLabel: string;
    countryCode: string;
    calendarDelay: number;
    permitAllZipCodes?: boolean;
    permittedZipCodeList?: PermittedZipCode[];
}

export class PermittedZipCode {
    zipCode: string;
    calendarDelay: number;
}

export class AppointmentWrapper {
    confirmedAppointment: Appointment;
    wishedAppointments: Appointment[];
}

export interface IFaultCode {
    code: string;
    label: string;
    condition: string;
}

export interface IFolderCreateRequestSnapshot {
    product: Product;
    customer: Customer;
    organization: Organization;
    origin: OriginEnum;
    stockClaim: boolean;
    purchaseInvoice: IPurchaseInvoice;
    site: Site;
    context: string;
    businessLink: string;
    warranty?: IWarranty;
    faultCode?: IFaultCode;
    oldReparationFolder?: string;
    originator?: Customer;
    productSubElement?: ProductSubElement;
    productAccessories?: ProductAccessory[];
    agentComment?: Comment;
    repairerComment?: Comment;
    supplierComment?: Comment;
    quantity: number;
    attachments?: FileInfo[];
}


export interface IFolderUpdateRequest {
    faultCode?: IFaultCode;
    newWarranty?: IWarranty;
    operationMode?: OperationModeEnum;
    customerComment?: any;
    agentComment?: Comment;
    repairerComment?: Comment;
    supplierComment?: Comment;
    pendingStatus?: any;
    customer?: Customer;
    customerReference?: string;
    survey?: ISurvey;
    attachment?: FileInfo;
    attachments?: FileInfo[];
    currentStatus?: string;
    currentWorkflowStatus?: ICurrentWorkflowStatus;
    serialNumber1?: string;
    serialNumber2?: string;
    sites?: Site[];
    tag?: string;
    quotation?: Quotation;
    reparationReport?: ReparationReport;
    quantity?: number;
    externalReferences?: Map<string, string>;
    product?: Product;
    appointmentIdToRemove?: string;
    shipment?: Shipment;
    shipmentToUpdate?: ShipmentUpdateRequest;
    shipmentToBeRemoved?: Shipment;
    services?: Service[];
    repairCenter?: RepairCenter;
    productLocation?: string;
    finalCustomerSolution?: string;
    orderId?: string;
    shipmentItems?: ShipmentItem[];
}

export interface ICurrentWorkflowStatus {
    status: string;
    workflowStatusDate: string;
    origin: string;
}

export interface Payment {
    paymentReference: string;
    paymentReason: PaymentReason;
    amount: number;
    currency: string;
    paymentMode: PaymentMode;
    createdDate: string;
    refunds: Refund[];
}

export class PaymentWrapper {
    constructor(payment: Payment, selected: boolean) {
        this.payment = payment;
        this.selected = selected;
    }

    payment: Payment;
    selected: boolean;
}

export interface Refund {
    refundReference: string;
    refundReason: PaymentReason;
    amount: number;
    currency: string;
    paymentMode: PaymentMode;
    createdDate: string;
}

export interface RefundRequest {
    folderId: string;
    paymentReference: string;
    refundAmount: Amount;
    refundReason: string;
}


export class SerialNumberRule {
    imageId: Array<string>;
    numberOfPhotos: number;
    requiredSerialNumber: boolean;
    requiredSecondSerialNumber: boolean;
    serialNumber1RegEx: string;
    serialNumber2RegEx: string;
}


// folder service
export class Service {
    id: string;
    type: ServiceType;
    quotationId: string;
    finished: boolean;
}

export class OperationMode {
    operationMode: string;
    switchOperationModeReason: string;
    switchedBy?: UserAction;

}

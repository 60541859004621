import {Component, Input, OnInit} from '@angular/core';
import {Folder, IFolderUpdateRequest, SerialNumberRule} from '../../../../models/folder.model';
import {Product} from '../../../../models/product.model';
import {CustomerChoiceEnum, OperationModeEnum} from '../../../../models/enums/operationMode.enum';
import {DetailSupplierDialogComponent} from './detail-supplier-dialog/detail-supplier-dialog.component';
import {FolderSubjectService} from '../../folder-subject.service';
import {MatDialog} from '@angular/material/dialog';
import {MomentPipe} from '../../../../../@fuse/pipes/moment.pipe';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ProductService} from '../../../../shared/services/product.service';
import {DetailRepairDialogComponent} from './detail-repair-dialog/detail-repair-dialog.component';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {Observable, of, ReplaySubject} from 'rxjs';
import {FolderService} from '../../../../shared/services/folder.service';
import {AddressType} from '../../../../models/enums/addressType.enum';
import {Technician} from '../../../../models/itinerant/technician.model';
import {Context} from '../../../../models/enums/context.enum';
import {AppointmentService} from '../../../../shared/services/appointment/appointment.service';
import {Service} from '../../../../models/services.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {hasScope, isAdminPlatana} from '../../../../store/user/user.selectors';
import {Constants} from '../../../../Constants';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {CustomerUtils} from '../../../../shared/utils/customer-utils';
import {ClaimSensibleHiddenField} from '../../../../models/claim-sensible/claim-sensible-hidden-field.model';
import {ProductDetailComponent} from '../../product-detail/product-detail.component';
import {SiteType} from '../../../../models/enums/siteType.enum';
import {Scopes} from '../../../../models/scopes/scopes.model';
import {UnknownProduct} from '../../create-form/unknown-product/unknown.product';
import {SupplierService} from '../../../../shared/services/supplier.service';
import {Supplier} from '../../../../models/supplier.model';
import {FormControl} from '@angular/forms';
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators';
import {SUPPLIER_CODE, SUPPLIER_LABEL} from '../../../../shared/generic/product-preview-card-all-details/product-supplier-field/product-supplier-field.component';
import {MapService} from '../../../../shared/utils/map-service';
import {SupplierConfirmDialogComponent} from './confirm-dialog-for-supplier/confirm-dialog-for-supplier.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../shared/services/back-office.service';

@Component({
    selector: 'app-folder-details-card',
    templateUrl: './folder-details-card.component.html',
    styleUrls: ['./folder-details-card.component.scss'],
    providers: [MomentPipe]
})
export class FolderDetailsCardComponent extends Unsubscriber implements OnInit {
    @Input() permission;

    public readonly UNKNOWN_PRODUCT_CODE = UnknownProduct.UNKNOWN_PRODUCT_CODE;
    public readonly UNKNOWN_PRODUCT_SUPPLIER = UnknownProduct.UNKNOWN_PRODUCT_SUPPLIER;

    folder: Folder;
    folderClosed: boolean;
    showSymptomActionEdit = true;
    showSerialNumberActionEdit = true;
    showProductCodeActionEdit = true;
    UNKNOWN_SUPPLIER = Constants.UNKNOWN_SUPPLIER;
    product: Product;
    supplierCode: string;
    serialNumber: string;
    secondSerialNumber: string;
    quantity: number;
    productCode: string;
    newProduct: Product;
    technician: Technician;
    service: Service;
    isQuantityEditable = false;
    productCodeFound = true;
    customerChoice: CustomerChoiceEnum;
    accessoriesConverted = '';
    isAdminPlatana$: Observable<boolean>;
    currentLanguage$: Observable<string>;
    claimSensibleHiddenField =  ClaimSensibleHiddenField;
    serialNumberInfo$: Observable<SerialNumberRule>;

    showSupplierActionEdit = true;

    suppliers: Supplier[] = [];
    public supplierCtrl: FormControl = new FormControl();
    public supplierFilteringCtrl: FormControl = new FormControl();
    public searching = false;
    public filteredSuppliers: ReplaySubject<Supplier[]> = new ReplaySubject<Supplier[]>(1);

    constructor(private dialog: MatDialog,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>,
                private productService: ProductService,
                private appointmentService: AppointmentService,
                private sharedService: SharedService,
                private backOfficeService: BackOfficeService,
                private supplierService: SupplierService,
                private mapService: MapService) {
        super();
    }

    ngOnInit(): void {
        this.isAdminPlatana$ = this.store$.pipe(select(isAdminPlatana));
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.folderSubjectService.folderAsObservable().subscribe(folder => {
            this.folder = folder;
            this.product = folder.product;
            this.supplierCode = this.product.supplier?.code;
            this.serialNumber = this.folder.serialNumber1;
            this.secondSerialNumber = this.folder.serialNumber2;
            this.quantity = this.folder.quantity;
            this.productCode = this.product.code;
            this.customerChoice = this.folder.customerChoice;
            this.folderClosed = this.folder.currentStatus.status.toString().startsWith('FOLDER_CLOSED');
            if (folder.context === Context.REPARATION && folder.appointment
                && folder.appointment.confirmedAppointment && folder.appointment.confirmedAppointment.appointmentId) {
                this.completeTechnicianAndServiceInformation(folder);
            } else {
                this.technician = null;
                this.service = null;
            }
            if (!!folder.productAccessories) {
                const accessoriesLabels = folder.productAccessories.map(acces => acces.label);
                this.accessoriesConverted = this.ConvertListToString(accessoriesLabels, ' - ');
            }
            this.isQuantityEditable = this.isEditable();
        });

        this.serialNumber = this.folder.serialNumber1;

        this.listenForSearchSupplierChanges();
    }

    private isEditable(): boolean {
        return !!this.folder.currentWorkflowStatus && ['SYMPTOM_SELECTED', 'SURVEY'].includes(this.folder.currentWorkflowStatus.status);
    }

    private completeTechnicianAndServiceInformation(folder: Folder): void {
        // fetch technician
        this.appointmentService.getTechnicianByAppointmentId(folder.appointment.confirmedAppointment.appointmentId).subscribe(technician => {
            this.technician = technician;
        }, () => this.technician = null);
    }

    onOpenDialogRepairerDetails(): void {
        const customerAddress = this.folder.customer.addresses ? CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.INTERVENTION) : null;
        if (customerAddress) {
            this.dialog.open(DetailRepairDialogComponent, {
                hasBackdrop: true, width: '750px', disableClose: false, autoFocus: false, data: {
                    repairer: this.folder.repairCenter,
                }
            });
        }
    }

    isOperatingModeHomeRepairOrServiceRepair(): boolean {
        return this.folder.operationMode === OperationModeEnum.HOME_REPAIR || this.folder.operationMode === OperationModeEnum.SERVICE_CENTER_REPAIR;
    }

    showDetailSupplier(): any {
        if (this.supplierCode === this.UNKNOWN_SUPPLIER) {
            return;
        }
        this.store$.dispatch(new StartLoading());
        this.supplierService.getByCode(this.supplierCode, this.folder.organization.code, this.folder.context)
            .subscribe(supplierDocument => {
                this.dialog.open(DetailSupplierDialogComponent, {
                    hasBackdrop: true,
                    disableClose: false,
                    autoFocus: false,
                    width: '800px',
                    data: {
                        supplier: supplierDocument
                    }
                });
                this.store$.dispatch(new StopLoading());
            });
    }

    showProductDetails(): any {
        this.fetchProduct();
    }

    fetchProduct(): void {
        this.store$.dispatch(new StartLoading());

        const filters = new Map<string, any>();
        filters.set(Constants.ORGANIZATION_CODE, this.folder.organization.code);
        filters.set(Constants.PRODUCT_CODE, this.product.code);
        filters.set(Constants.WITH_SELLING_PRICE, true);
        filters.set(Constants.WITH_PURCHASE_PRICE, true);
        filters.set(Constants.WITH_DOCUMENTS, true);

        this.productService.search(filters)
            .pipe(
                map(page => page.content),
                filter(products => products.length > 0),
                map(products => products[0]),
            )
            .subscribe(product => {
                    this.dialog.open(ProductDetailComponent, {
                        width: '80%',
                        hasBackdrop: true,
                        disableClose: false,
                        autoFocus: false,
                        data: {
                            purchaseDate: this.folder.purchaseInvoice.purchaseDate,
                            product: product,
                            managementSiteCode: this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE).code
                        }
                    });
                },
                error => this.store$.dispatch(new StopLoading()),
                () => this.store$.dispatch(new StopLoading())
            );
    }

    getDate(purchaseDate: string): Moment {
        return moment(purchaseDate);
    }

    getCustomerCommentPanneConfirmed(): string {
        if (!!this.folder.customerComments) {
            const customerComment = this.folder.customerComments[CommentType.COMMENT_PANNE_CONFIRMED];
            return customerComment ? customerComment.content : null;
        }
        return null;
    }

    updateSymptom(): void {
        if (!this.folderClosed) {
            this.showSymptomActionEdit = !this.showSymptomActionEdit;
        }
    }

    closeEditSerialNumberMode(): void {
        this.showSerialNumberActionEdit = !this.showSerialNumberActionEdit;
        this.serialNumber = this.folder.serialNumber1;

    }

    openEditProductCode(): void {
        this.showProductCodeActionEdit = !this.showProductCodeActionEdit;

    }

    closeEditProductCodeFolderMode(): void {
        this.showProductCodeActionEdit = !this.showProductCodeActionEdit;
        this.productCode = this.product.code;

    }

    updateSymptomFolder(folderUpdateRequest: IFolderUpdateRequest): void {
        if (!!folderUpdateRequest) {
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(value => {
                this.folder = value;
                this.folderSubjectService.folderLoaded(value);
            });
        }
        this.showSymptomActionEdit = true;
    }
    getSerialNumberRule(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.serialNumberInfo$ =   this.backOfficeService.getSerialNumberRule(ruleEvaluationContext);
    }

    updateSerialNumberFolder(serialNumber: string): void {
        if (serialNumber !== this.folder.serialNumber1) {
            const folderUpdateRequest: IFolderUpdateRequest = {
                serialNumber1: serialNumber.trim()
            };
            this.updateFolder(folderUpdateRequest);
            this.sharedService.updateSerialNumber(serialNumber);
        } else {
            this.closeEditSerialNumberMode();
        }
    }

    private updateFolder(folderUpdateRequest: IFolderUpdateRequest): void {
        if (!!folderUpdateRequest) {
            this.store$.dispatch(new StartLoading());
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(value => {
                this.folder = value;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
        }
    }

    updateSecondSerialNumberFolder(serialNumber: string): void {
        if (serialNumber !== this.folder.serialNumber1) {
            const folderUpdateRequest: IFolderUpdateRequest = {
                serialNumber2: serialNumber.trim()
            };
            this.updateFolder(folderUpdateRequest);
          this.sharedService.updateSerialNumber2(serialNumber);

        } else {
            this.closeEditSerialNumberMode();
        }
    }

    hasSecondSerialNumber(): boolean {
        return !!this.secondSerialNumber;
    }

    updateProductCodeFolder(): void {
        if (!this.productCodeFound || this.productCode.length < 6) {
            return;
        }
        if (this.productCode !== this.product.code && !!this.newProduct) {
            const folderUpdateRequestProduct: IFolderUpdateRequest = {
                product: this.newProduct
            };
            this.store$.dispatch(new StartLoading());
            this.folderService.updateFolder(this.folder.id, folderUpdateRequestProduct).then(value => {
                this.folder = value;
                this.product = value.product;
                this.productCode = this.product.code;
                this.store$.dispatch(new StopLoading());
                this.showProductCodeActionEdit = true;
            }, () => {
                this.store$.dispatch(new StopLoading());
            });

        } else {
            this.closeEditProductCodeFolderMode();
        }
    }

    updateQuantity(quantity: number): void {
        if (quantity !== this.folder.quantity) {
            this.store$.dispatch(new StartLoading());

            this.folderService.updateFolder(this.folder.id, {quantity: quantity})
                .then(folder => {
                    this.folder = folder;
                    this.folderSubjectService.folderLoaded(this.folder);
                    this.store$.dispatch(new StopLoading());
                }, () => {
                    this.store$.dispatch(new StopLoading());
                });
        }
    }

    isFolderPayersPresent(): boolean {
        if (!this.folder || !this.folder.payers) {
            return false;
        }
        let isAllAttributesNotEmpty = false;
        Object.keys(this.folder.payers).forEach(attributeName => {
            if (!!this.folder.payers[attributeName]) {
                isAllAttributesNotEmpty = true;
            }
        });
        return isAllAttributesNotEmpty;
    }

    private ConvertListToString(listToConvert: string[], separator: string): string {
        return listToConvert.join(separator);
    }

    isFolderCustomerChoicePresent(): boolean {
        return !(!this.folder || !this.folder.customerChoice);
    }

    isCustomerDetailPresent(): boolean {
        return !!(this.folder.customer?.firstName || this.folder.customer?.lastName || this.folder.customer?.contact?.email
            || this.folder.customer?.contact?.mobileNumber);
    }

    public hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));


    protected readonly Scopes = Scopes;

    onEditSupplier() {
        this.showSupplierActionEdit = !this.showSupplierActionEdit;

        if (!this.showSupplierActionEdit) {
            this.store$.dispatch(new StartLoading());
            this.supplierService.search(0, 30, this.folder.organization.code, this.folder.context, new Map<string, any>())
                .subscribe(pageOfSuppliers => {
                        this.suppliers = pageOfSuppliers.content;
                        this.store$.dispatch(new StopLoading());
                    },
                    () => {
                        this.suppliers = [];
                        this.store$.dispatch(new StopLoading());
                    });
        }
    }

    canEditSupplier(): boolean {
        return (this.folder.operationMode === OperationModeEnum.PRE_DIAGNOSTIC ||
            ['WAITING_REPARATION_AGREEMENT', 'WAITING_SUPPLIER_CONFIRMATION'].includes(this.folder.currentWorkflowStatus.status));
    }

    validateChangeSupplier() {
        const dialogRef = this.dialog.open(SupplierConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.afterClosed().subscribe(isChangeConfirmed => {
            if (isChangeConfirmed) {
                this.store$.dispatch(new StartLoading());
                const supplierCode = this.supplierCtrl.value.code.trim();
                this.folderService.changeSupplierAndNotify(this.folder.id,
                    supplierCode,
                    isChangeConfirmed.replayNotifications.toString(),
                    isChangeConfirmed.replayReports.toString()
                )
                    .subscribe((folder: Folder) => {
                            this.showSupplierActionEdit = true;
                            this.folderSubjectService.folderLoaded(folder);
                            this.store$.dispatch(new StopLoading());
                        },
                        () => {
                            this.store$.dispatch(new StopLoading());
                        });
            }
        });
    }

    private listenForSearchSupplierChanges() {
        // listen for search field value changes
        this.anotherSubscription = this.supplierFilteringCtrl.valueChanges
            .pipe(
                filter(searchValue => !!searchValue),
                tap(() => {
                    this.store$.dispatch(new StartLoading());
                    this.searching = true;
                }),
                debounceTime(600),
                switchMap(searchValue => this.fetchSuppliers(searchValue)),
            )
            .subscribe(filteredSuppliers => {
                    this.searching = false;
                    this.filteredSuppliers.next(filteredSuppliers);
                    this.store$.dispatch(new StopLoading());
                },
                () => {
                    this.searching = false;
                    this.store$.dispatch(new StopLoading());
                });

    }

    fetchSuppliers(searchValue: string): Observable<Supplier[]> {
        if (!this.suppliers) {
            return of<Supplier[]>();
        }

        const filters: Map<string, any> = new Map<string, any>();
        if (!!searchValue) {
            filters.set(SUPPLIER_CODE, searchValue);
            filters.set(SUPPLIER_LABEL, searchValue);
        }

        return this.supplierService.search(0, 30, this.folder.organization.code, this.folder.context, this.mapService.mapToObj(filters))
            .pipe(
                map(pageOfSuppliers => {
                    return pageOfSuppliers.content;
                })
            );
    }

    hasSellingPrice(): boolean {
        // Vu l'urgence de la situation, on doit afficher le prix de vente dans le détail dossier UNIQUEMENT si le dossier a été créé à partir d'une facture
        // TODO Il faut revoir tt l'écran création dossier, détail produit, ...
        return this.folder.purchaseInvoice
            && this.folder.purchaseInvoice.number !== 'N/C'
            && !!this.folder.purchaseInvoice.sellingPrice;
    }
}

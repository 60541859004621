import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {AppState} from '../../../../store/app.state';
import {LoadDashboard} from '../../../../store/dashboard/dashboard.actions';
import {BoldBI} from '@boldbi/boldbi-embedded-sdk';
import {Unsubscriber} from '../../../../unsubscriber';

@Component({
    selector: 'view-dashboard',
    template: '<div fxFlex id="view-dashboard"></div>'
})
export class ViewDashboardComponent extends Unsubscriber implements OnInit, OnDestroy {

    private readonly containerId = 'view-dashboard';

    constructor(private store$: Store<AppState>,
                private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.anotherSubscription = this.activatedRoute.params.subscribe(param => {
            this.store$.dispatch(new LoadDashboard({
                dashboardId: param.id,
                embedContainerId: this.containerId,
                mode: BoldBI.Mode.View
            }));
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        const instance = BoldBI.getInstance(this.containerId);
        setTimeout(() => {
            instance?.destroyStyles();
        }, 1000);
    }

}




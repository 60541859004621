import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BackOfficeService} from '../../services/back-office.service';
import {TypeOfServiceEnum} from '../../../models/typeOfService.model';
import {startWith, switchMap} from 'rxjs/operators';
import {ConfigurationReferential} from '../../../models/configurationReferential.model';
import {ConfigCodeMapsEnum} from '../../services/configuration-item-enum';
import {Iris} from '../../../models/reparationReport/iris.model';
import {PAYERS_CONFIGS} from '../../data/static.config';
import {FolderSubjectService} from '../../../main/folder/folder-subject.service';
import {Folder} from '../../../models/folder.model';
import {WarrantyReasonEnum} from '../../../models/enums/warrantyReason.enum';
import {WarrantyReasonDetails} from '../../../models/warrantyReasonDetails';
import {Vat} from 'app/models/vat.model';
import {select, Store} from '@ngrx/store';
import {countryCode, margin, vats} from '../../../store/organization/organization.selectors';
import {currentUser} from '../../../store/user/user.selectors';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {SparePartService} from '../../services/spare-part.service';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {TypeOfBenefitService} from '../../services/typeOfBenefit.service';
import * as accents from 'remove-accents';
import {TranslateService} from '@ngx-translate/core';
import {RelatedSparePart, RelatedSparePartRequest, RelatedSparePartView} from '../../../models/spare-parts/relatedSparePart.model';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {SiteType} from '../../../models/enums/siteType.enum';
import {ProductFamilyType} from '../../../models/enums/productFamilyType.enum';
import {ConfigV2Service} from '../../services/config-v2.service';
import {RegimeWarrantyEnum} from '../../../models/warrantyRule.model';

@Component({
    selector: 'app-report-modal',
    templateUrl: 'report-modal.component.html',
    styleUrls: ['report-modal.component.scss']
})
export class ReportModalComponent extends Unsubscriber implements OnInit {

    vatsList$: Observable<Vat[]>;

    warrantyReason: string;
    sparePartsTypes = [];
    payers = PAYERS_CONFIGS;
    isLoading = false;
    warrantyReasonDetails: WarrantyReasonDetails;

    reportLineColumns: string[] = ['type', 'code', 'label', 'payer', 'quantity', 'price', 'discount', 'totalLinePrice', 'discountReason', 'action'];
    irisColumns: string[] = ['symptom', 'condition', 'repair', 'defect', 'section', 'action'];

    dataSourceReportLine = [];
    reportLineDataTable = new MatTableDataSource<any>();

    dataSourceIris = [];
    irisDataTable = new MatTableDataSource<any>();

    reportLineForm: any = {
        form: null
    };

    filteredSymptomList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    symptomList = [];

    filteredConditionList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    conditionList = [];

    filteredRepairList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    repairList = [];

    filteredDefectList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    defectList = [];

    filteredSectionList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    sectionList = [];

    typeOfServices = new Array<any>();
    filteredTypeOfServices: Observable<any[]>;

    irisForm: any = {
        form: null,
    };

    folder: Folder;
    currency: string;
    countryCode: string;

    organizationCode: string;
    context: string;
    private sparePartsRelatedToProduct: RelatedSparePartView[] = [];

    private defaultVat;
    defaultMargin = 0;
    sellingPriceSubscription: Subscription;

    constructor(private dialogRef: MatDialogRef<ReportModalComponent>,
                private backOfficeService: BackOfficeService,
                private typeOfBenefitService: TypeOfBenefitService,
                private translateService: TranslateService,
                private store$: Store<AppState>,
                @Inject(MAT_DIALOG_DATA) public reportModalData,
                public folderSubject: FolderSubjectService,
                private sparePartService: SparePartService,
                private configV2Service: ConfigV2Service) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => { this.defaultMargin = value; });
        this.initTypeOfBenefitService();
        this.initDefaultVat();
        this.getWarrantyReasonDetails();
        if (this.reportModalData.loadTypeOfBenefit) {
            this.initTypeOfServices();
        }
        if (!this.reportModalData.hideIris) {
            this.initIrisForm();
        }
        if (this.reportModalData.loadSparePartCatalog) {
            this.searchRelatedSpareParts();
        }
        if (this.reportModalData) {
            this.initWithData();
        }

    }

    private initWithData() {
        this.currency = this.reportModalData.currency;
        this.reportModalData.reportLine.forEach(quotationLine => {
            quotationLine.totalLinePrice = this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, quotationLine.tva);
        });
        this.reportLineDataTable.data = this.reportModalData.reportLine;
        this.irisDataTable.data = this.reportModalData.iris;
        this.dataSourceIris = this.reportModalData.iris;
        this.dataSourceReportLine = this.reportModalData.reportLine;
        this.warrantyReason = this.reportModalData.warrantyReason;
    }

    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.sparePartsTypes.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.sparePartsTypes.push(value);
            });
        });
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    private getWarrantyReasonDetails(): void {
        const folder$ = this.folderSubject.folder$;
        this.anotherSubscription = this.store$.pipe(select(currentUser)).pipe(
            switchMap(user => {
                this.organizationCode = user.organizationCode;
                this.context = user.context;
                return folder$;
            }),
            switchMap(folder => {
                this.folder = folder;
                const ruleEvaluationContext = RuleEvaluationContext.fromFolder(folder);
                return this.backOfficeService.getWarrantyReasonDetails(ruleEvaluationContext);
            })
        )
            .subscribe(warrantyReasonDetails => {
                this.warrantyReasonDetails = warrantyReasonDetails;
                this.initReportLineForm();
            });
    }

    initTypeOfServices(): void {
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.TYPE_OF_SERVICE]).subscribe(
            response => {
                if (this.reportModalData.workforcesRule?.length > 0) {
                    this.typeOfServices = response.filter(item => item.type !== TypeOfServiceEnum.WORKFORCE);
                } else {
                    this.typeOfServices = response;
                }
            }
        );
    }

    initReportLineForm(): void {
        this.reportLineForm.form = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            type: new FormControl(TypeOfServiceEnum.SPARE_PART, Validators.required),
            payer: new FormControl(this.warrantyReasonDetails?.spareParts || '', Validators.required),
            tva: new FormControl(this.defaultVat, Validators.required),
            quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
            price: new FormControl(null, [Validators.required, Validators.min(0.01)]),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            totalLinePrice: new FormControl(0),
            discountReason: new FormControl(null),
            supplierCode: new FormControl(null),
            currency: new FormControl(null)
        });
    }

    initIrisForm(): void {
        this.irisForm.form = new FormGroup({
            symptom: new FormControl(null, Validators.required),
            symptomCodeCtrl: new FormControl(''),
            condition: new FormControl(null, Validators.required),
            conditionCodeCtrl: new FormControl(''),
            reparation: new FormControl(null, Validators.required),
            reparationCodeCtrl: new FormControl(''),
            defect: new FormControl(null, Validators.required),
            defectCodeCtrl: new FormControl(''),
            section: new FormControl(null, Validators.required),
            sectionCodeCtrl: new FormControl('')
        });
        this.filtredIrisForm();
    }

    filtredIrisForm(): void {
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.IRIS_SYMPTOMS]).subscribe(
            response => {
                this.symptomList = response;
                this.updateList('symptomCodeCtrl', this.filteredSymptomList, this.symptomList);
            }
        );
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.CONDITIONS]).subscribe(
            response => {
                this.conditionList = response.concat([
                    {
                        id: 'CONSTANT',
                        code: 'CONSTANT',
                        label: 'COMPONENT.CONSTANT.CONDITION'
                    }
                ]);
                this.updateList('conditionCodeCtrl', this.filteredConditionList, this.conditionList);
            }
        );
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.REPAIR]).subscribe(
            response => {
                this.repairList = response;
                this.updateList('reparationCodeCtrl', this.filteredRepairList, this.repairList);
            }
        );
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.IRIS_SECTIONS]).subscribe(
            response => {
                this.sectionList = response;
                this.updateList('sectionCodeCtrl', this.filteredSectionList, this.sectionList);
            }
        );
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.IRIS_DEFECTS]).subscribe(
            response => {
                this.defectList = response;
                this.updateList('defectCodeCtrl', this.filteredDefectList, this.defectList);
            }
        );
    }

    private updateList(formName: string, list$, list) {
        this.anotherSubscription = this.irisForm.form.get([formName]).valueChanges
            .pipe(startWith(null)).subscribe(input => list$.next(this.filterListByCodeOrLabel(input, list)));
    }

    private filterListByCodeOrLabel(input, list) {
        return !!input ?
            list.filter(item =>
                item.code.toLowerCase().includes(input.toLowerCase()) || item.label.toLowerCase().includes(input.toLowerCase())
            ) : list;
    }

    addIntoSparePartList(): void {
        this.checkDiscountValue();
        this.dataSourceReportLine.push(this.reportLineForm.form.value);
        this.refreshDataTableSparePart();
        this.initReportLineForm();
    }

    onEnterPressed(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    deleteLineSparePart(indexLine: any): void {
        this.dataSourceReportLine = this.reportLineDataTable.data.filter((item, index) => index !== indexLine);
        this.refreshDataTableSparePart();
    }

    refreshDataTableSparePart(): void {
        this.reportLineDataTable.data = [];
        this.reportLineDataTable = new MatTableDataSource<any>(this.dataSourceReportLine);
    }

    addIntoIrisList(): void {
        let iris: Iris;
        iris = {
            symptom: {
                code: this.irisForm.form.value.symptom.code,
                label: this.irisForm.form.value.symptom.label
            },
            condition: {
                code: this.irisForm.form.value.condition.code,
                label: this.irisForm.form.value.condition.label
            },
            reparation: {
                code: this.irisForm.form.value.reparation.code,
                label: this.irisForm.form.value.reparation.label
            },
            section: {
                code: this.irisForm.form.value.section.code,
                label: this.irisForm.form.value.section.label
            },
            defect: {
                code: this.irisForm.form.value.defect.code,
                label: this.irisForm.form.value.defect.label
            }
        };
        this.dataSourceIris.push(iris);
        this.refreshDataTableIris();
        this.refreshDataForm(this.irisForm.form);
    }

    refreshDataTableIris(): void {
        this.irisDataTable.data = [];
        this.irisDataTable = new MatTableDataSource<any>(this.dataSourceIris);
    }

    refreshDataForm(form: FormGroup): void {
        Object.keys(form.controls).forEach(key => {
            if (key !== 'quantity') {
                form.controls[key].setValue(null);
            }
            form.controls[key].setErrors(null);
        });
        form.setErrors({'invalid': true});
    }

    deleteLineDataIris(indexLine: any): void {
        this.dataSourceIris = this.irisDataTable.data.filter((item, index) => index !== indexLine);
        this.refreshDataTableIris();
    }

    private filterTypeOfService(value: any, criteria: string): string[] {
        const filterValue = value[criteria]?.toLowerCase();
        return value && filterValue ? this.typeOfServices.filter(item => item.type === value.type &&
            accents.remove(item[criteria].toLowerCase()).includes(accents.remove(filterValue))) : this.typeOfServices;
    }

    onCodeChanges(): void {
        const type = this.reportLineForm.form.get('type').value;
        const code = this.reportLineForm.form.get('code').value;

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('code', code);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.reportModalData?.workforcesRule?.length > 0) {
            this.reportModalData.workforcesRule.filter(item => item.code === code);
        } else {
            if (this.reportModalData.loadTypeOfBenefit) {
                this.searchTypeOfService('code', code, type);
            } else {
                this.filteredTypeOfServices = of([]);
            }
        }
    }

    onLabelChanges(): void {
        const type = this.reportLineForm.form.get('type').value;
        const label = this.reportLineForm.form.get('label').value;

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('label', label);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.reportModalData.workforcesRule?.length > 0) {
            this.reportModalData.workforcesRule.filter(item => item.code === label);
        } else {
            if (this.reportModalData.loadTypeOfBenefit) {
                this.searchTypeOfService('label', label, type);
            } else {
                this.filteredTypeOfServices = of([]);
            }
        }
    }

    private searchTypeOfService(criteria: string, value: string, type: string) {
        const inputs = {
            'type': type
        };
        inputs[criteria] = value;

        this.isLoading = true;
        this.filteredTypeOfServices = of(this.filterTypeOfService(inputs, criteria));
        this.isLoading = false;
    }

    private searchSpareParts(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.sparePartsRelatedToProduct.filter(sparePart => accents.remove(sparePart[criteria].toLowerCase()).includes(accents.remove(value.toLowerCase())));
            if (filteredByCode.length === 0) {
                this.filteredTypeOfServices = of([]);
            } else {
                this.filteredTypeOfServices = of(filteredByCode);
            }
        } else {
            this.filteredTypeOfServices = of(this.sparePartsRelatedToProduct);
        }
    }

    sparePartChanged(event): void {
        this.clearReportLineForm();
        this.reportLineForm.form.get('code').setValue(event.option.value.code);
        this.reportLineForm.form.get('label').setValue(event.option.value.label);
        this.reportLineForm.form.get('supplierCode').setValue(event.option.value.supplier?.code || null);
        this.reportLineForm.form.get('currency').setValue(event.option.value.approximatePrice?.currency || null);

        const type = this.reportLineForm.form.get('type').value;
        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.calculateSellingPrice(event);
        } else {
            this.reportLineForm.form.get('price').setValue(event.option.value.price);
            this.calculationReportLine();
        }
    }

    private calculateSellingPrice(event) {
        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }
        this.store$.dispatch(new StartLoading());
        const sparePart = event.option.value;
        const purchasePrice = event.option.value.purchasePrice || event.option.value.supplier?.unitPrice?.value;
        const approximatePrice = !!event.option.value.approximatePrice?.value ? event.option.value.approximatePrice.value : 0;

        this.sellingPriceSubscription = this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice)
            .subscribe(data => {
                if (!!data) {
                    sparePart.price = data.sellingPrice || 0;
                    sparePart.marginPercentage = data.marginPercentage || 0;
                    sparePart.purchasePrice = data.purchasePrice || 0;
                }
            }, () => {
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.completeFormWithPrices(event);
            });
    }

    private completeFormWithPrices(event) {
        const price = this.getSellingPrice(event);
        this.reportLineForm.form.get('price').setValue(price);
        this.calculationReportLine();
        this.store$.dispatch(new StopLoading());
    }

    private getSellingPrice(event) {
        return this.isFreeSparePart(event.option.value.supplier) ? 0 : event.option.value.price || 0;
    }

    private isFreeSparePart(supplier) {
        return supplier?.freeUnderWarranty && this.isUnderWarranty();
    }

    private isUnderWarranty() {
        return this.folder.newWarranty.warranty === RegimeWarrantyEnum.SG;
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    validateForm(): void {
        this.dialogRef.close({
            reportLine: this.reportLineDataTable.data,
            iris: !!this.irisDataTable ? this.irisDataTable.data : [],
        });
    }

    saveReparation($event: any, sparePart: any) {
        sparePart.reparation = $event.value;
    }

    calculationReportLine(): void {
        this.reportLineForm.form.get('price').setValue(this.sparePartService.formatPriceAccordingToCurrency(this.reportLineForm.form.get('price').value, this.currency));
        this.reportLineForm.form.get('totalLinePrice').setValue(
            this.sparePartService.formatPriceAccordingToCurrency(this.sparePartService.getTotalLinePrice(this.reportLineForm.form.value.price,
                this.reportLineForm.form.value.quantity, this.reportLineForm.form.value.discount, this.reportLineForm.form.value.tva), this.currency));
    }

    onTypeChange($event: any): void {
        if ($event === TypeOfServiceEnum.SPARE_PART) {
            this.filteredTypeOfServices = of(this.sparePartsRelatedToProduct);
            this.reportLineForm.form.controls.payer.setValue(this.warrantyReasonDetails?.spareParts || '');
        } else if ($event === TypeOfServiceEnum.WORKFORCE && this.reportModalData?.workforcesRule?.length > 0) {
            this.loadWorkforce($event);
            this.setPayerForType($event);
        } else {
            this.changeFileredTypeOfService($event);
            this.setPayerForType($event);
        }
        this.clearReportLineForm();
    }

    private loadWorkforce(type: string) {
        this.filteredTypeOfServices = of(this.reportModalData.workforcesRule);
    }

    private changeFileredTypeOfService(type) {
        const typeOfService = this.typeOfServices.filter(item => item.type === type);
        this.filteredTypeOfServices = of(typeOfService);
    }

    setPayerForType(type: string): void {
        switch (type) {
            case TypeOfServiceEnum.DISPLACEMENT: {
                this.reportLineForm.form.controls.payer.setValue(this.warrantyReasonDetails?.displacement || '');
                break;
            }
            case TypeOfServiceEnum.WORKFORCE: {
                this.reportLineForm.form.controls.payer.setValue(this.warrantyReasonDetails?.workforce || '');
                break;
            }
            case TypeOfServiceEnum.QUOTATION_FEES: {
                this.reportLineForm.form.controls.payer.setValue('CLIENT');
                break;
            }
            default: {
                this.reportLineForm.form.controls.payer.setValue('');
            }
        }
    }

    private clearReportLineForm() {
        this.reportLineForm.form.get(['code']).setValue(null);
        this.reportLineForm.form.get(['label']).setValue(null);
        this.reportLineForm.form.get(['price']).setValue(null);
        this.reportLineForm.form.get(['totalLinePrice']).setValue(0);
        this.reportLineForm.form.get(['quantity']).setValue(1);
        this.reportLineForm.form.get(['discount']).setValue(0);
        this.reportLineForm.form.get(['tva']).setValue(this.defaultVat);
        this.reportLineForm.form.get(['supplierCode']).setValue(null);
        this.reportLineForm.form.get(['currency']).setValue(null);
        this.reportLineForm.form.setErrors({'invalid': true});
    }

    disabled(): boolean {
        if (this.warrantyReason === WarrantyReasonEnum.SG_IN_MANUFACTURER_WARRANTY_PERIOD) {
            return false;
        } else {
            return this.reportLineDataTable.data.length <= 0;
        }
    }

    private searchRelatedSpareParts() {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe(code => this.countryCode = code);

        const relatedSparePartRequest: RelatedSparePartRequest = {
            productCode: this.folder.product?.code,
            supplierCode: this.folder.product?.supplier?.code,
            brand: this.folder.product?.brand,
            eanCode: this.folder.product?.codeEAN,
            family1: RuleEvaluationContext.getFamilyByType(this.folder.product?.families, ProductFamilyType.FAMILY),
            countryCode: this.countryCode,
            managementSiteCode: this.getManagementSiteCode(),
            withMedia: false
        };

        this.sparePartService.getRelatedSpareParts(relatedSparePartRequest)
            .subscribe(spareParts => {
                spareParts.forEach(value => this.sparePartsRelatedToProduct.push(this.buildSparePartView(value)));
                this.filteredTypeOfServices = of(this.sparePartsRelatedToProduct);
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
    }

    private buildSparePartView(value: RelatedSparePart) {
        return {
            id: value.id,
            code: value.code,
            label: value.label,
            price: value?.purchasePrice?.value,
            information: value.information,
            approximatePrice: value?.approximatePrice,
            supplier: value.suppliers?.length > 0 ? value.suppliers[0] : null,
            marginPercentage: value.marginPercentage,
        };
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    private checkDiscountValue() {
        if (!this.reportLineForm.form.value.discount) {
            this.reportLineForm.form.get(['discount']).setValue(0);
        }
    }
}

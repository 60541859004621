import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IEvent} from '../../../../models/events.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Folder} from '../../../../models/folder.model';
import {MatSort} from '@angular/material/sort';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {AppState} from '../../../../store/app.state';
import {TranslateService} from '@ngx-translate/core';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';
import {map} from 'rxjs/operators';


@Component({
    selector: 'app-folder-events-card',
    templateUrl: './folder-events-card.component.html',
    styleUrls: ['./folder-events-card.component.scss']
})
export class FolderEventsCardComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() events = [];

    folder: Folder;
    displayedColumns: string[] = ['event', 'user', 'date'];
    dataSourceEvents = new MatTableDataSource<IEvent>([]);
    isSortAsc = true;
    currentLanguage$: Observable<string>;
    userTasks: InstructionUserTask[] = [];

    constructor(private store$: Store<AppState>,
                private translateService: TranslateService,
                private backOfficeService: BackOfficeService) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.dataSourceEvents = new MatTableDataSource<IEvent>();
        this.fetchEvents();
    }

    findUserTask(taskCode: string): Observable<InstructionUserTask> {
        return of(this.userTasks.find(task => task.code.toLowerCase() === taskCode.toLowerCase()) ?? ({'code': taskCode} as InstructionUserTask));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.events.currentValue) {
            this.backOfficeService.getInstructionUserTask().subscribe(
                response => {
                    this.userTasks = response;
                    this.fetchEvents();
                }
            );
        }
    }

    ngAfterViewInit(): void {
        this.dataSourceEvents.paginator = this.paginator;
    }

    public sortByDate(): void {
        this.dataSourceEvents.data.sort((a: IEvent, b: IEvent) => {
            const date1 = new Date(a.userAction.actionDate);
            const date2 = new Date(b.userAction.actionDate);
            if (this.isSortAsc) {

                return +date2.getTime() - +date1.getTime();
            } else {
                return +date1.getTime() - +date2.getTime();
            }
        });
    }

    public updateSort(): void {
        this.isSortAsc = !this.isSortAsc;
        this.sortByDate();
    }

    fetchEvents(): void {
        this.dataSourceEvents.data = this.events;
        if (!!this.events) {
            this.sortByDate();
        }
        this.dataSourceEvents.paginator = this.paginator;
        this.dataSourceEvents.sort = this.sort;
    }

    getTranslatedEventName(element): Observable<string> {
        const key = (element.eventType === 'WORKFLOW_STATUS' ? 'COMPONENT.' : 'FOLDER.EVENT.ENUM_LABELS.') + element.eventName;
        return this.translateService.get(key).pipe(
            map(translatedKey => translatedKey !== key ? translatedKey : element.eventName)
        );
    }
}
